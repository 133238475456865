import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../Contexts/Auth/AuthContext'
const Menu = ({ children,isMenu = true  }) => {
    return <>
        <Link className="navbar-minimalize minimalize-styl-2 btn btn-primary " to="#"><i className="fa fa-bars"></i> </Link>
    </>
}
const Search = ({ children, items }) => {
    return <><form role="search" className="navbar-form-custom" action="search_results.html">
        <div className="form-group">
            <input type="text" placeholder="Campo de pesquisa" className="form-control" name="top-search" id="top-search" />
        </div>
    </form></>
}
const Associations = ({ provider_association }) => {
    return <><li className="dropdown">
        <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
            <i className="fa fa-map-marker"></i> [ Teresina ]
            {/* <i className="fa fa-map-marker"></i> [ = $_SESSION['FILIAIS_INIT']['filial_name'] ; ] */}
        </Link>
        <ul className="dropdown-menu dropdown-messages">
            {
                !provider_association.length ? <li>
                    <div className="text-center link-block">
                        <strong>Nenhuma filial cadastrada</strong>
                        <i className="fa fa-angle-right"></i>
                    </div>
                </li>
                    :
                    provider_association.map((el, i) => (
                        <>
                            <li>
                                <Link to="#">
                                    <div>
                                        <i className="fa fa-map-marker fa-fw"></i>
                                        {el.client_name}
                                    </div>
                                </Link>
                            </li>
                            {
                                i > 0 &&
                                <li className="dropdown-divider"></li>
                            }


                        </>
                    ))
            }
        </ul>
    </li></>
}
const User = ({ children }) => {
    return <li>
        <span className="m-r-sm text-muted welcome-message">{children}</span>
    </li>
}
const Icons = ({ children }) => {
    return <>{children}</>
}
const Alerts = ({ children }) => {
    return <>
        <li className="dropdown">
            <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
                <i className="fa fa-bell"></i>
            </Link>
            <ul className="dropdown-menu dropdown-alerts">
                <li>
                    <Link to="#">
                        <div>
                            <i className="fa fa-envelope fa-fw"></i> Nenhuma mensagens
                            <span className="float-right text-muted small">4 minutos atrás</span>
                        </div>
                    </Link>
                </li>
                <li className="dropdown-divider"></li>

                <li>
                    <Link to="#">
                        <div>
                            <i className="fa fa-twitter fa-fw"></i> Não há notificações
                        </div>
                    </Link>
                </li>

                <li className="dropdown-divider"></li>
                <li>
                    <Link to="#">
                        <div>
                            <i className="fa fa-upload fa-fw"></i> Serviços atualizados
                            <span className="float-right text-muted small">4 minutos atrás</span>
                        </div>
                    </Link>
                </li>
                <li className="dropdown-divider"></li>
                <li>
                    <div className="text-center link-block">
                        <Link to="#">
                            <strong>Ver todos os alertas</strong>
                            <i className="fa fa-angle-right"></i>
                        </Link>
                    </div>
                </li>
            </ul>
        </li>
    </>
}

const Notifications = ({ provider_user }) => {
    return <li className="dropdown">
        <Link className="dropdown-toggle count-info" data-toggle="dropdown" to="#">
            <i className="fa fa-envelope"></i>
        </Link>
        <ul className="dropdown-menu dropdown-messages">
            <li>
                <div className="dropdown-messages-box">
                    <Link className="dropdown-item float-left" to="profile.html">
                        <img alt="image" className="rounded-circle" src="/madsengenharia.com.br/admin/img/profile_small.jpg" />
                    </Link>
                    <div className="media-body">
                        <small className="float-right">46h ago</small>
                        <strong>{provider_user.USER_NAME.toUpperCase()}</strong> foi adicionado ao sistema <strong>Usuário Root</strong>. <br />
                        <small className="text-muted">2 dias atrás às 7:58 pm -  date("d") - 2; .02.2022</small>
                    </div>
                </div>
            </li>

            <li className="dropdown-divider"></li>
            <li>
                <div className="text-center link-block">
                    <Link to="mailbox.html" className="dropdown-item">
                        <i className="fa fa-envelope"></i> <strong>Ler todas as notificações</strong>
                    </Link>
                </div>
            </li>
        </ul>
    </li>
}

const Logout = ({ children }) => {
    return <><li>
        <Link to="/">
            <i className="fa fa-sign-out"></i> Sair
        </Link>
    </li>
    </>
}


  const getMenuComponent = (children) => {
    // Procura pelo componente Layout.Header.Menu nos filhos
    return React.Children.toArray(children).find(
      (child) =>{
        console.log("child.type.display Name",child);
        
      }
    );
  };
const Header = ({ children }) => {
    const { provider_current_client, provider_user, provider_association } = useContext(AuthContext)

    const items = [];
    


    
    console.log("Layout.Header.Menu",getMenuComponent(children));

    return (
        <>
            <div className="row border-bottom">
                <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
                    <div className="navbar-header">
                        {/* {
                            items.includes("Menu") &&
                        }
                        {
                            items.includes("Search") &&
                        } */}
                        <Menu />
                        <Search />
                    </div>

                    <ul className="nav navbar-top-links navbar-right">                       
                        {/* {
                            items.includes("User") &&
                        }
                        {
                            items.includes("Associations") &&
                        }
                        {
                            items.includes("Notifications") &&
                        }
                        {
                            items.includes("Alerts") &&
                        }
                        {
                            items.includes("Logout") &&
                        } */}
                        <User>{provider_user.USER_NAME.toUpperCase()} </User>
                        <Associations provider_association={provider_association} />
                        <Notifications provider_user={provider_user}/>
                        <Alerts/>
                        <Logout/>
                    </ul>
                </nav>
            </div>
        </>

    );
}

Header.Menu = Menu;
Header.Search = Search;
Header.User = User;
Header.Associations = Associations;
Header.Icons = Icons;
Header.Icons.Notifications = Notifications;
Header.Icons.Alerts = Alerts;
Header.Icons.Logout = Logout;
Header.Menu.displayName = "Layout.Header.Menu";

export default Header;


