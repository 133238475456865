import React, { useEffect, useState, useContext } from 'react'
import inputSearch from './inputSearch';
import table from './table';
import div from './div';
import utils from './utils';
import modal from './modal';
import button from './button';


export const InputSearch = inputSearch
export const Table = table
export const Div = div
export const Utils = utils
export const Modal = modal
export const Button = button

//export default Tag; 