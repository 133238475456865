
import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

import Permission from '../../utils/Permission.js';
import CustomInput from '../../components/CustomInput.jsx';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import UCCreateAliquota from './useCaseCreate.js';
import Uaddtional from './useAdditional.js';
import { mes, ano, aliquota } from './useComponent.js';


/* LAYOUT */
import { Div, Modal } from '../../styles/tag.jsx';
//import Layout from '../../styles/layout.jsx';

/* select personalizados */
import SelectSingleDbClient from '../../components/selectSingleDbClient.V2.jsx';
import SelectSingleDbColaborador from '../../components/selectSingleDbColaborador.V2.jsx';
import SelectMultiDbColaborador from '../../components/selectMultiDb.V2.jsx';

const FragmentModelCreate = forwardRef((props, ref) => {
    const { provider_session } = useContext(AuthContext)
    const [isShowModalChange, setModalChange] = useState(props.isOpen);
    // #NÃO APAGAR# const { listData, getBroadcast, setEditData, setAction } = useContext(ContentContext)

    const [visibled, setVisible] = useState(props.visible);

    const modalRef = useRef();

    const buttonRef = useRef(null);
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        handleClickCancel,
    } = UCCreateAliquota();

    const { selectedValue, where, setSelectedValue, handleSelect1Change } = Uaddtional()

    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        open: (param) => {
            if (typeof param === 'object' && param !== null) {
                //setAction("patch");
                //formik.setValues(param)
            } else {
                //setAction("post");
            }
            console.log("paramparamparamparam", param);

            setVisible(param);
            /* 
            ESSE CÓDIGO ESTÁ 100% FUNCIONAL
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show(); */
        }
    }));

    const openModal = () => {
        if (modalRef.current) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    };

    const closeModal = () => {
        //buttonRef.current.click(); // Simula o clique no botão
        setModalChange(false)
    };

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])

    useEffect(() => {
        console.log("useEffect onResume", onResume, editData);
        if (editData != null)
            openModal();
    }, [editData])

    useEffect(() => {
        console.log("useEffect", isShowModalChange);
        if (isShowModalChange) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        } else {
            //buttonRef.current.click(); // Simula o clique no botão
        }
    }, [isShowModalChange])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay loading={true} zIndex="9999" message="Carregando..." overlayColor="rgba(0,153,255,0.5)" />
            }
            
            <Modal visibled={visibled} action={action} onHide={(e) => { if (!visibled) return; setVisible(false); }}>
                <Modal.header>
                    <Modal.header.title/>
                </Modal.header>
                <Modal.body>
                    <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation" noValidate id="form-create-update222222">
                        <Div.row>
                            <Div.formRow>
                                <div className="col-md-4 mb-3">
                                    <CustomInput UComponent={aliquota} formik={formik} args={{ action: action }} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <CustomInput UComponent={mes} formik={formik} args={{ action: action }} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <CustomInput UComponent={ano} formik={formik} args={{ action: action }} />
                                </div>
                            </Div.formRow>
                        </Div.row>
                        <Div.row>
                            <Div.formRow>
                                <div className="col-md-4 mb-3">
                                    <SelectSingleDbClient target="x1" onChange={handleSelect1Change} formik={formik} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <SelectSingleDbColaborador label="Colaborador" field="x1" relatedValue={where} formik={formik} autoload={false} />
                                    {/* <SelectSingleDbColaborador standBy label='Colaborador 2' relatedValue={where} field="x23" formik={formik} /> */}
                                </div>
                                <div className="col-md-4 mb-3">
                                    <SelectMultiDbColaborador target="x1" relatedValue={where} formik={formik} />
                                </div>
                            </Div.formRow>
                        </Div.row>
                    </form>
                </Modal.body>
                <Modal.footer>
                    <MyButton
                        name="btnSave"
                        text="Salvar"
                        type="submit"
                        loading={isSubmiting}
                        icon="fa fa-save"
                        btnClass="btn btn-primary ml-2" />
                </Modal.footer>
            </Modal>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentModelCreate;