import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';

const Header = ({ children }) => {
    return <>{children}</>;
};

const Body = ({ children }) => {
    return <div>
        {children}
    </div>;
};

// Subcomponente Title
const Title = (str) => {

    return <>{str == "post" ? 'Novo registro' : 'Editar registro'}</>;
};

const Footer = ({ children }) => {
    return <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row bg-faded">
        {children}
    </div>;
};

const Modal = ({ children, action, modal, visibled, onHide }) => {
    const [visible, setVisible] = useState(visibled);
    useEffect(() => {
        setVisible(visibled);
    }, [visibled]);
    return <>
    
        <Dialog header={Title(action)} visible={visible} {...modal} style={{ width: '50vw' }} onHide={onHide}> {children}</Dialog>
    </>

};

// Adicionando os subcomponentes ao `ibox`
Modal.header = Header;
Modal.header.title = Title;
Modal.body = Body;
Modal.footer = Footer;


export default Modal;
