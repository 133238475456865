import React from 'react'

const InputSearch  = ({args }) => {
   
    return (
        <>
            <div className="d-flex justify-content-sm-end">
                <div className="search-box ms-2">
                    <input type="text" className="form-control search" placeholder="Procurar..." />
                    <i className="ri-search-line search-icon"></i>
                </div>
            </div>

        </>

    );
}
export default InputSearch ;
