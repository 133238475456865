import React , { forwardRef }from "react";

// Componente principal `ibox`
const Div = ({ children,className,id }) => {
    return <div className={className} id={id}>
        {children}
    </div>;
};

const Row = forwardRef(({ children, className='' }, ref) => {
    return <Div className={`row ${className}`} >{children}</Div>;
});

const col_lg_12 = forwardRef(({ children, className='' }, ref) => {
    return <div className="col-lg-12">{children}</div>
});
const col_sm_auto = forwardRef(({ children, className='' }, ref) => {
    return <div className="col-sm-auto">{children}</div>
});

const FormRow = forwardRef(({ children, className='' }, ref) => {
    return <Div className={`form-row ${className}`} >{children}</Div>;
});

const Col = forwardRef(({ children, className }, ref) => {
    return <Div className={className}>{children}</Div>;
});

// Adicionando os subcomponentes ao `ibox`
Div.row = Row;
Div.formRow = FormRow;
Div.col = Col;
Div.col_lg_12 = col_lg_12;
Div.col_sm_auto = col_sm_auto;

export default Div;
