import React, { useEffect, useState, useRef, useContext, forwardRef } from 'react'
import { Link } from 'react-router-dom'

/* PRIME REACT */
import { Paginator } from 'primereact/paginator';

/* COMPONENTES */
import SweetButton from '../../components/SweetButton';

/* UTILITÁRIOS */
import SingMask from '../../utils/SingMask';
import usePermission from '../../utils/Permission.js';

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import FragmentModelCreate from './fragmentModalCreate';
import UseCaseGeneratePdf from './useCaseGeneratePdf.js';

/* ESTILOS CSS */
//import 'primereact/resources/themes/lara-light-purple/theme.css';
import useCase from './useCase.js';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import ShowToast from '../../components/Toast.jsx';
import { usePaginator } from '../../utils/Paginator.js'
import FragmentModelImport from './fragmentModalImport.jsx';
import FragmentModelSearch from './fragmentModalSearch.jsx';

const FragmentList = forwardRef((props, ref) => {
    const btnDelete = useRef();
    const modalRef = useRef();
    const modalRefImport = useRef();
    const modalRefSearch = useRef();

    const containerRef = useRef(null);

    const { getTotalRecords } = usePaginator()
    const { isLoading, setIsLoading, onResume, first, rows, template1, state, handlerDelete, reloadList, onPageChange } = useCase();

    const { setAuth, auth, provider_user, provider_session, provider_current_client, provider_permission } = useContext(AuthContext)
    const { listData, getBroadcast, workSpace } = useContext(ContentContext)
    const [isShow_paginator, setShow_paginator] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [permission, setPermission] = useState({});
    const [isDisabledButtonDeleteGroup, setIsDisabledButtonDeleteGroup] = useState(true);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [flags, setFlags] = useState({
        A04: false,
        A01: false,
        A02: false,
        A03: false,
        A04: false
    });

    const {
        hasStatus,
        hasFlag
    } = usePermission(provider_permission);

    const handleCheckboxChange = (event) => {
        const { checked, value } = event.target;
        if (checked)
            setCheckboxValues(prev => [...prev, value]);
        else
            setCheckboxValues(prev => prev.filter(item => item !== value));
    };

    const handleCheckAll = (e) => {
        setCheckboxValues([])
        const checkboxes = containerRef.current.querySelectorAll('.form-check-input');
        checkboxes.forEach(checkbox => {
            if (isCheckedAll == true)
                setCheckboxValues(prev => [...prev, checkbox.value]);
        });
    };

    useEffect(() => {
        if (btnDelete.current != undefined) {
            let RES = checkboxValues.length > 0 ? false : true
            setIsDisabledButtonDeleteGroup(RES)
        }

    }, [checkboxValues])

    useEffect(() => {
        if (getBroadcast != null)
            if (getBroadcast.command == "resetButtonDeleteGroup")
                setIsDisabledButtonDeleteGroup(true)
    }, [getBroadcast])

    useEffect(() => {
        setPermission(provider_permission.filter(el => el.screen_id == "504")[0]);
        reloadList(null);        
        setFlags({
            A00: hasFlag("A00", "504"),
            A01: hasFlag("A01", "504"),
            A02: hasFlag("A02", "504"),
            A03: hasFlag("A03", "504"),
            A04: hasFlag("A04", "504")
        });
    }, [])

    useEffect(() => {
        if (isLoading == false && isReady == false)
            setIsReady(true)
    }, [isLoading])

    useEffect(() => {
        console.log('%c~ * ~ FRAGMENTLIST :: getResult foi atualizado ~ * ~', 'color: white; background-color: pink; padding: 4px;font-size: 18px');
        console.log("listData", listData);
        setCheckboxValues([])
    }, [listData])

    const openModal = (e) => {
        modalRef.current?.open(true)
    };

    const openModalImport = (e) => {
        modalRefImport.current?.open(true)
    };
    const openModalSearch = (e) => {
        setIsLoading(true);
        modalRefSearch.current?.open(true)
    };

    const openModalEdit2 = (e) => {
        modalRef.current?.open(e)
    };

    return (
        <>
            {
                isLoading &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }
<FragmentModelCreate ref={modalRef} checkboxValues={checkboxValues} />
            {/* 
            <FragmentModelImport ref={modalRefImport} checkboxValues={checkboxValues} /> */}
            <FragmentModelSearch ref={modalRefSearch} checkboxValues={checkboxValues} />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Aliquota de serviços</h5>
                                <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                        <i className="fa fa-wrench"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-user">
                                        <li><a href="#" className="dropdown-item">Config option 1</a>
                                        </li>
                                        <li><a href="#" className="dropdown-item">Config option 2</a>
                                        </li>
                                    </ul>
                                    <a className="close-link">
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="ibox-content">
                                {isReady == false ?
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                        </button>
                                    </div> : (
                                        <div className="card-body">
                                            <div className="listjs-table" id="customerList">
                                                <div className="row g-4 mb-3">
                                                    <div className="col-sm-auto">
                                                        <div className="list-grid-nav hstack gap-1">
                                                            {/* <button type="button" className="btn btn-success add-btn" onClick={broadCast} id="create-btn" data-bs-toggle="modal" data-bs-target="#exampleModalgrid"><i className="ri-add-line align-bottom ml-1"></i> Novo</button> */}
                                                            <button className="btn btn-success" onClick={() => openModal(true)}><i className="fa fa-plus"></i> Novo</button>
                                                           
                                                            {/* <button className="btn btn-soft-danger" onClick={() => new ExportXLS().generate({ template: "Folha de Pagamento", type: "xls", body_content: getResult, outPutFilename: "Turmas - total" })}><i className="ri-printer-line"></i></button> */}
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="d-flex justify-content-sm-end">
                                                            <div className="search-box ms-2">
                                                                <input type="text" className="form-control search"
                                                                    placeholder="Procurar..." />
                                                                <i className="ri-search-line search-icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-card mt-3 mb-1">
                                                    <table className="table align-middle table-nowrap table-striped table-bordered table-hover" id="customerTable">
                                                        <thead className="table-light">
                                                            <tr>
                                                                {
                                                                    flags.A04 &&
                                                                    <th scope="col" style={{ width: '50px' }}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} type="checkbox"
                                                                                id="checkAll" value="option" />
                                                                        </div>
                                                                    </th>
                                                                }
                                                                <th className="sort" data-sort="aliquota_id">ID.</th>
                                                                <th className="sort" data-sort="filial_id">Filial</th>
                                                                <th className="sort" data-sort="aliquota_status_id">Aliquota</th> 
                                                                <th className="sort" data-sort="aliquota_id">Mês</th>
                                                                <th className="sort" data-sort="valor">Ano</th>                                                                
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all" ref={containerRef}>
                                                            {!listData.length ? <>
                                                                <tr>
                                                                    <td colSpan="14">
                                                                        <div className="noresult">
                                                                            <div className="text-center">
                                                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                                                                                    trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                                                <h5 className="mt-2">Sinto muito! Não há resultados.</h5>
                                                                                <p className="text-muted mb-0">Não encontramos nenhum registro em nosso banco de dados.</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </> :
                                                                (
                                                                    listData.map(el => (
                                                                        <tr key={el.aliquota_id}>
                                                                            {
                                                                                flags.A04 &&
                                                                                <th scope="row">
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox" checked={checkboxValues.includes(el.aliquota_id)} name="chk_child" value={el.aliquota_id} onChange={handleCheckboxChange} />
                                                                                    </div>
                                                                                </th>
                                                                            }
                                                                            <td nowrap="nowrap">{el.aliquota_id}</td>
                                                                            <td nowrap="nowrap">{el.client_name} ({el.cnpj})</td>
                                                                            <td nowrap="nowrap">{el.aliquota}</td>
                                                                            <td nowrap="nowrap">{el.mes}</td>
                                                                            <td nowrap="nowrap">{el.ano}</td>
                                                                            
                                                                           
                                                                        </tr>
                                                                    )))
                                                            }
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                {
                                                                    flags.A04 && <th></th>
                                                                }
                                                                <th className="sort">ID.</th>
                                                                <th className="sort">Filial</th> 
                                                                <th className="sort">Aliquota</th> 
                                                                <th className="sort">Mês</th>
                                                                <th className="sort">Ano</th>                                                                
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentList;

