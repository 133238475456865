
import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
//import Alert from '../../components/Alert.jsx';
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

//import Permission from '../../utils/Permission.js';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import UCCreateFolha_pgto_item from './useCaseCreate.js';

/* select personalizados */
import useCaseLotacao from '../lotacao/useCase.js';

//import SingMask from '../../utils/SingMask';
/* select personalizados */
//import InputHiddenFolhaPgto from '../../components/inputHiddenFolhaPgto.jsx';
//import SelectSingleDbColaborador from '../../components/selectSingleDbColaborador.jsx';
import SelectSingleDbClient from '../../components/selectSingleDbClient.jsx';
//import NoneStatus from '../../components/noneStatus.jsx';


const FragmentModelImport = forwardRef((props, ref) => {
    const { provider_session } = useContext(AuthContext)
    
    const { listData, getBroadcast, setEditData, setAction,workSpace } = useContext(ContentContext)
    const [isShowModalChange, setModalChange] = useState(props.isOpen);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [checkPgtoItems, setCheckPgtoItems] = useState([]);

    const modalRefImport = useRef();
    const buttonRef = useRef(null);
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        closeModal1,
        handleClickCancel,
        teste,
    } = UCCreateFolha_pgto_item();

    const { listing, loadCol } = useCaseLotacao();


    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        open: (param) => {
            if (typeof param === 'object' && param !== null) {
                setAction("patch");
                formik.setValues(param)
            } else {
                setAction("post");
            }
            const modal = new window.bootstrap.Modal(modalRefImport.current);
            modal.show();
        }
    }));
    const handleCheckboxLotacao = (e, obj) => {

        const { checked, value } = e.target;
        // Criar um novo objeto, para não alterar diretamente o original
        const fp = workSpace.folha_pgto;
        const updatedObj = {
            ...obj,
            folha_pgto_name:fp.folha_pgto_name,
            valor: "0.00",
            folha_pgto_id: fp.folha_pgto_id
        };
        
        const { folha_pgto_id, valor, colaborador_id,folha_pgto_name,colaborador_name } = updatedObj;
        const selectedProperties2 = { folha_pgto_id, valor, colaborador_id,folha_pgto_name,colaborador_name };
        
        console.log("handleCheckboxLotacao",obj,selectedProperties2);
        if (checked) {
            setCheckboxValues(prev => [...prev, obj]);
            setCheckPgtoItems(prev => [...prev, selectedProperties2]);
        }
        else {
            setCheckboxValues(prev => prev.filter(item => item.colaborador_id !== obj.colaborador_id));
            setCheckPgtoItems(prev => prev.filter(item => item.colaborador_id !== selectedProperties2.colaborador_id));
        }
    };

    const openModal = () => {
        if (modalRefImport.current) {
            const modal = new window.bootstrap.Modal(modalRefImport.current);
            modal.show();
        }
    };

    const closeModal = () => {
        buttonRef.current.click(); // Simula o clique no botão
        setModalChange(false)
    };

    const loadColaborador = (e) => {
        loadCol(e);
        setCheckboxValues([])
    };

    const onClick = (e) => {
        ;
        console.log("deu certo", checkPgtoItems);
        checkPgtoItems.map(el => {
            teste(el)
        })

    };
    useEffect(() => {
        //if (btnDelete.current != undefined) {
        let RES = checkboxValues.length > 0 ? false : true
        console.log("[checkboxValues]", checkboxValues);

        //setIsDisabledButtonDeleteGroup(RES)
        //}

    }, [checkboxValues])

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])

    useEffect(() => {
        closeModal()
    }, [closeModal1])

    useEffect(() => {
        console.log("useEffect onResume", onResume, editData);
        if (editData != null)
            openModal();
    }, [editData])

    useEffect(() => {
        console.log("useEffect", isShowModalChange);
        if (isShowModalChange) {
            const modal = new window.bootstrap.Modal(modalRefImport.current);
            modal.show();
        } else {
            buttonRef.current.click(); // Simula o clique no botão
        }
    }, [isShowModalChange])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }

            <div className="modal" id="exampleModalgrid" ref={modalRefImport} tabindex="-1" aria-labelledby="exampleModalgridLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalgridLabel">
                                {
                                    action == "patch" ? "Ediçãodwdwd" : "BUSCA AVANÇADA"
                                }
                            </h5>

                            <button type="button" className="close" ref={buttonRef} data-dismiss="modal"><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>

                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12 m-b-xs">
                                    <SelectSingleDbClient field="colaborador_id" onChange={loadColaborador} label="Filial" formik={formik} />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap table-striped table-bordered table-hover" id="customerTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: '50px' }}>
                                                <div className="form-check">
                                                    {/*  <input className="form-check-input" onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} type="checkbox"
                                                                            id="checkAll" value="option" /> */}
                                                </div>
                                            </th>
                                            <th className="sort" data-sort="lotacao_id">ID</th>
                                            <th className="sort" data-sort="colaborador_id">Colaborador</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all" /* ref={containerRef} */>
                                        {!listing.length ? <>
                                            <tr>
                                                <td colSpan="14">
                                                    <div className="noresult">
                                                        <div className="text-center">
                                                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                                                                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                            <h5 className="mt-2">Sinto muito! Não há resultados.</h5>
                                                            <p className="text-muted mb-0">Não encontramos nenhum registro em nosso banco de dados.</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </> :
                                            (
                                                listing.map(el => (

                                                    <tr key={el.lotacao_id}>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="icheckbox_square-green" type="checkbox" checked={checkboxValues.includes(el)} name="chk_child" value={el} onChange={(e) => handleCheckboxLotacao(e, el)} />
                                                            </div>
                                                        </th>
                                                        <td nowrap="nowrap">{el.lotacao_id}</td>
                                                        <td nowrap="nowrap">{el.colaborador_name}</td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <MyButton
                                name="btnSave"
                                text="Importar"
                                type="submit"
                                loading={isSubmiting}
                                icon="fa fa-save"
                                onClick={(e) => onClick(e)}
                                btnClass="btn btn-primary ml-2" />
                        </div>
                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentModelImport;
