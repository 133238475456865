import React from "react";

// Subcomponente para o título
const Title = ({ children }) => {
  return <div className="ibox-title">{children}</div>;
};

const Body = ({ children }) => {
  return <div className="card-body">{children}</div>;
};

// Subcomponente para o conteúdo
const Content = ({ children }) => {
  return <div className="ibox-content">{children}</div>;
};

// Componente principal `ibox`
const Card = ({ children }) => {
  return <div className="ibox-container">{children}</div>;
};

// Adicionando os subcomponentes ao `ibox`
Card.title = Title;
Card.body = Body;
Card.content = Content;

export default Card;
