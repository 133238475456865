
import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'

/* COMPONENTES */
import MyButton from '../../components/MyButton'

/* AUXILIARES */
//import Alert from '../../components/Alert.jsx';
import { AuthContext } from '../../Contexts/Auth/AuthContext.js';

import Permission from '../../utils/Permission.js';
import UCCreateSubscriber from './useCaseCreate.js';
import CustomInput from '../../components/CustomInput.jsx';
import ShowToast from '../../components/Toast.jsx';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import UCCreateFolha_pgto_item from './useCaseCreate.js';
import { mes, ano, aliquota } from '../../components/useComponent.js';
/* select personalizados */


/* select personalizados */
//import InputHiddenFolhaPgto from '../../components/inputHiddenFolhaPgto.jsx';
import SelectSingleDbColaborador from '../../components/selectSingleDbColaborador.jsx';
import SelectSingleDbClient from '../../components/selectSingleDbClient.V2.jsx';
//import NoneStatus from '../../components/noneStatus.jsx';


const FragmentModelCreate = forwardRef((props, ref) => {
    const { provider_session } = useContext(AuthContext)
    const [isShowModalChange, setModalChange] = useState(props.isOpen);
    const { listData, getBroadcast, setEditData, setAction } = useContext(ContentContext)

    const modalRef = useRef();
    const buttonRef = useRef(null);
    const {
        formik,
        onResume,
        isSubmiting,
        editData,
        action,
        closeModal1,
        handleClickCancel,
    } = UCCreateFolha_pgto_item();

    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        open: (param) => {
            if (typeof param === 'object' && param !== null) {
                setAction("patch");
                formik.setValues(param)
            } else {
                setAction("post");
            }
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    }));

    const openModal = () => {
        if (modalRef.current) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        }
    };

    const closeModal = () => {
        buttonRef.current.click(); // Simula o clique no botão
        setModalChange(false)
    };

    useEffect(() => {
        console.log("useEffect onResume", onResume);
        if (onResume != null)
            closeModal()
    }, [onResume])

    useEffect(() => {
        closeModal()
    }, [closeModal1])

    useEffect(() => {
        console.log("useEffect onResume", onResume, editData);
        if (editData != null)
            openModal();
    }, [editData])

    useEffect(() => {
        console.log("useEffect", isShowModalChange);
        if (isShowModalChange) {
            const modal = new window.bootstrap.Modal(modalRef.current);
            modal.show();
        } else {
            buttonRef.current.click(); // Simula o clique no botão
        }
    }, [isShowModalChange])

    return (
        <>
            {
                isSubmiting &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }

            <div className="modal" id="exampleModalgrid" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalgridLabel">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalgridLabel">
                                {
                                    action == "patch" ? "Edição" : "Criação"
                                }
                            </h5>

                            <button type="button" className="close" ref={buttonRef} data-dismiss="modal"><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>

                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation" noValidate id="form-create-update222222">
                                <div className="panel-content">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                        <SelectSingleDbClient formik={formik}/>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <CustomInput UComponent={aliquota} formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <CustomInput UComponent={mes}  formik={formik} args={{ action: action }} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <CustomInput  UComponent={ano}  formik={formik} args={{ action: action }} />
                                        </div>
                                        
                                        {/*   {
                                            action == "patch" &&  ok 2
						} */}
                                    </div>
                                </div>
                                <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">                                   
                                    <MyButton
                                        name="btnSave"
                                        text="Salvar"
                                        type="submit"
                                        loading={isSubmiting}
                                        icon="fa fa-save"
                                        btnClass="btn btn-primary ml-2" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentModelCreate;
