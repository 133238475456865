import React from 'react'
import Layout from '../styles/layout.jsx';
const Header = () => {
    
    return <Layout.Header>
        <Layout.Header.Menu />
        <Layout.Header.Search />
        <Layout.Header.User/>
        <Layout.Header.Associations/>
        <Layout.Header.Icons>
            <Layout.Header.Icons.Notifications/>
            <Layout.Header.Icons.Alerts />
            <Layout.Header.Icons.Logout />            
        </Layout.Header.Icons>
    </Layout.Header>

};
export default Header;