import React from "react";

// Subcomponente para o título
const LoadingPage = ({ children }) => {
    return <div className="d-flex justify-content-center">
        <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando página...
        </button>
    </div>
};

// Subcomponente para o conteúdo
const Content = ({ children }) => {
    return <div className="ibox-content">{children}</div>;
};

const CheckAllInput = ({ onChange }) => {
    return <div className="form-check">
        <input className="form-check-input" onChange={onChange} type="checkbox" id="checkAll" value="option" />
    </div>;
};

const CheckOneInput = ({ checked,value,onChange }) => {
    return <div className="form-check">
    <input className="form-check-input" type="checkbox" checked={checked} name="chk_child" value={value} onChange={onChange} />
</div>;
};

// Subcomponente para o conteúdo
const NoResult = ({ children }) => {
    return <div className="noresult">
        <div className="text-center">
            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
            <h5 className="mt-2">Sinto muito! Não há resultados.</h5>
            <p className="text-muted mb-0">Não encontramos nenhum registro em nosso banco de dados.</p>
        </div>
    </div>;
};

// Componente principal `ibox`
const Utils = ({ children }) => {
    return { children };
};

// Adicionando os subcomponentes ao `ibox`
Utils.loadingPage = LoadingPage;
Utils.content = Content;
Utils.noResult = NoResult;
Utils.checkAllInput = CheckAllInput;
Utils.checkOneInput = CheckOneInput;

export default Utils;
