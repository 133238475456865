import React from 'react'

const Content = ({ children, showPagination }) => {

    return (
        <>
        {
            showPagination && <div className="d-flex justify-content-end">
            <div className="pagination-wrap hstack gap-2">                    
                    {children}                    
            </div>
        </div> 
        }
                       
        </>

    );
}
export default Content;


