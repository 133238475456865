import { useState, useEffect } from 'react';

const usePermission = (str, args = null) => {
    //console.log("usePermission");

    // Estados para armazenar valores
    const [actionsOfCS, setActionsOfCS] = useState([]);
    const [permission, setPermission] = useState([]);
    const [permissionOfCS, setPermissionOfCS] = useState([]);
    const [thisAction, setThisAction] = useState('');
    const [thisScreenId, setThisScreenId] = useState('');
    const [thisCurrentClientId, setThisCurrentClientId] = useState('');
    const [flags, setFlags] = useState({
        A00: false,
        A01: false,
        A02: false,
        A03: false,
        A04: false
    });

    const verbs = {
        show: "A00",
        post: "A01",
        create: "A01",
        list: "A02",
        patch: "A03",
        delete: "A04",
        search: "A05",
        view: "A06"
    };

    //console.log(`----------------- CARREGOU!!  -------------------------`);

    // Funções para definir e obter os valores
    const getAction = () => thisAction;

    const getScreenId = () => thisScreenId;

    const getCurrentClientId = () => thisCurrentClientId;

    const getPermission = () => permission;

    // Lógica para verificar a permissão
    const flag = (flagDesejada, args = undefined) => {
        const getScreenIdLocal = args ?? getScreenId();

        // Verifica se é uma permissão genérica
        if (flagDesejada in verbs) flagDesejada = verbs[flagDesejada];

        const index = str.find(
            (el) => el.screen_id === getScreenIdLocal && el.client_id_array.split(',').includes(getCurrentClientId())
        );

        if (flagDesejada) {
            if (index) {
                const actions = index.permission.match(/A\d{2}/g);
                setActionsOfCS(actions);

                let permissionArray = index.permission.split(',');
                permissionArray = actions.concat(permissionArray);
                setPermissionOfCS(permissionArray);

                return permissionArray.includes(flagDesejada);
            } else {
                return false;
            }
        } else {
            return index;
        }
    };

    // Outra função para verificar flag
    const hasStatus = (statusClass, screenId = undefined) => {
        const getScreenIdLocal = screenId ?? getScreenId();


        const statt = str.find((el) => el.screen_id === getScreenIdLocal);
        return statt.permission_status[statusClass]

    }

    const hasFlag = (flagDesejada, screenId = undefined) => {
        const getScreenIdLocal = screenId ?? getScreenId();
        //Verifica se a flag está dentro dos verbos principais
        if (flagDesejada in verbs)
            flagDesejada = verbs[flagDesejada];

        //Busca as permissões na referida tela
        const screen = str.find((el) => el.screen_id === getScreenIdLocal);

        //se existir uma flag e a tela etiver dentro das permissões
        if (flagDesejada && screen) {
            const acoes_permitidas = screen.permission.match(/A\d{2}/g);
            let permissionArray = screen.permission.split(',');
            permissionArray = acoes_permitidas.concat(permissionArray);
            return permissionArray.includes(flagDesejada);
        } else {
            return false;
        }
    };
/* 
Neste {useEffect} ele é opcional ate o momento para o <Aside> devido la exigir várias telas... no entanto se torna obrigatório para todas as demais páginas.
Em todas as outras situações deve-se informar o ID da página no qual está sendo manipulada
*/
    useEffect(() => {
        if (args != null) {
            setPermission(str.filter(el => el.screen_id == args.screen_id)[0]);
            setFlags({
                A00: hasFlag("A00", args.screen_id),
                A01: hasFlag("A01", args.screen_id),
                A02: hasFlag("A02", args.screen_id),
                A03: hasFlag("A03", args.screen_id),
                A04: hasFlag("A04", args.screen_id)
            });
        }

    }, [])

    /*  const hasFlag = (flagDesejada, screenId = undefined) => {
 
 
     } */



    // Retorna as funções e os estados que podem ser usados no componente
    return {
        flags,
        setFlags,
        setAction: setThisAction,
        getAction,
        permissionOfCS,
        setPermission,
        getPermission,
        setScreenId: setThisScreenId,
        getScreenId,
        setCurrentClientId: setThisCurrentClientId,
        getCurrentClientId,
        flag,
        hasStatus,
        hasFlag
    };
};

export default usePermission;
