import React, { useEffect, useState, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';
import { InputText } from 'primereact/inputtext';
//import { ProgressSpinner } from 'primereact/progressspinner';


const SelectSingleDbColaborador = ({ autoload, relatedValue, field, formik, loading, onChange, options, optionSelected, onListener, target, required, onBlurHandler, labelField, placeholder, label, args }) => {
    let _label = label ?? 'Colaborador 1'
    let _field = field ?? "x1"

    /* const [listcolaborador, isLoadingcolaborador, isErrorcolaborador] = useRead({
        table: "tb_colaborador",
        src: 'colaborador',
        where: [["colaborador_id", "!=", "0"]],
        orderBy: "colaborador_name asc",
        limit: "150"
    }, ReadSearch); */



    const where = {
        table: "tb_colaborador",
        src: 'colaborador',
        where: [["colaborador_id", "!=", "0"]],
        orderBy: "colaborador_name asc",
        limit: "150"
    }

    const [listcolaborador, isLoadingcolaborador, isErrorcolaborador, refresh, isLock] = useRead(where, ReadSearch, { autoload: autoload });

    const ntfy = (e) => {
        console.log("ntfy", e);
        //onChange(e);
    }

    useEffect(() => {
        if (relatedValue && relatedValue.component == _field) {
            console.log(`Select colaborador ${_field} Recebeu`, relatedValue);
            const newData = { ...where, ...relatedValue }
            console.log("newData", newData);
            refresh(newData);
        }

    }, [relatedValue]);
    return (
        <>
            
            {
                isLock ?
                    <><label htmlFor={field}>{label}</label>
<br/>
                        <InputText
                            type="text"
                            placeholder='Aguardando... '
                            disabled
                            className='pl-1'
                            label={_label}
                        />

                        {/* <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />  */}
                    </>
                    :
                    isLoadingcolaborador ?
                        <>
                            <InputText
                                type="text"
                                placeholder={`Carregando...`}
                                disabled
                                label={_label}
                                args={args} />

                            {/* <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />  */}
                        </>
                        :
                        <CustomDropdown
                            field={_field}
                            label={_label}
                            options={[listcolaborador, 'tb_colaborador', 'colaborador_name', 'colaborador_id']}
                            labelField="name"
                            loading={isLoadingcolaborador}
                            {...target}
                            onChange={onChange}
                            listener={onListener}
                            optionSelected='colaborador_name'
                            callBackOptionSelected={(e) => ntfy(e)}
                            required={true}
                            //onBlurHandler={()=> loadConfigCertificate()}
                            placeholder="Colaborador"
                            formik={formik}
                        />
            }
        </>

    );
}

export default SelectSingleDbColaborador; 