import React, { useEffect, useState, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';
import { InputText } from 'primereact/inputtext';
//import { ProgressSpinner } from 'primereact/progressspinner';


const SelectSingleDbClient = ({ relatedValue, field, formik, loading, onChange, options, optionSelected, onListener, target, required, onBlurHandler, labelField, placeholder, label, args }) => {
    let _label = label??'Filial'
    let _field = field??"client_id"

    /* const [listClient, isLoadingClient, isErrorcolaborador] = useRead({
        table: "tb_client",
        src: 'colaborador',
        where: [["client_id", "!=", "0"]],
        orderBy: "client_name asc",
        limit: "150"
    }, ReadSearch); */

    useEffect(() => {
        if (relatedValue)
            console.log("relatedValue::", _field, relatedValue);
    }, [relatedValue]);

    const where = {
        table: "tb_client",
        src: 'client',
        where: [["client_id", "!=", "0"]],
        orderBy: "client_name asc",
        limit: "150"
    }

    const [listClient, isLoadingClient, isErrorcolaborador, refresh] = useRead(where, ReadSearch);

    const ntfy = (e) => {
        console.log("Callback Client", e);
        e.target=target
        //refresh();
        if(onChange)
        onChange(e);
    }

    return (
        <>
            {isLoadingClient ?
                <>
                    <InputText
                        type="text"
                        placeholder='Carregando... '
                        loading={isLoadingClient}
                        disabled
                        label={_label}
                        args={args} />

                    {/* <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />  */}
                </>
                :
                <CustomDropdown
                    field={_field}
                    label={_label ?? "Colaborador"}
                    options={[listClient, 'tb_client', 'client_name', 'client_id']}
                    labelField="name"
                    loading={isLoadingClient}
                    {...target}                    
                    onChange={onChange}
                    listener={onListener}
                    optionSelected='client_name'
                    callBackOptionSelected={(e) => ntfy(e)}
                    required={true}
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Colaborador"
                    formik={formik}
                />
            }
        </>

    );
}

export default SelectSingleDbClient; 