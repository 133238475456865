import React, { useEffect, useState, useRef, useContext } from 'react'

//import FragmentForm from './fragmentForm'; 
/* import FragmentSearch from './fragmentSearch'; */
import FragmentList from './fragmentList';
import { Aside, Header, Footer } from "../../fragments/index";
//import ButtonShortCut from '../../components/ButtonShortCut.jsx'
//import Permission from '../../utils/Permission.js'

//##import { AuthContext } from '../../Contexts/Auth/AuthContext'
import ContentProvider from '../../Contexts/Contents/Provider.js';
import Layout from '../../styles/layout.jsx';

function Class() {
//    const { setAuth, auth, provider_session, provider_current_client} = useContext(AuthContext)    
    return (
        <ContentProvider>
            <Layout.view>
                <Aside />
                <Layout.view.content>
                    <Header />
                    <Layout.breadCrumb screen_name="aliquota"/>
                    {/* <FragmentForm /> */}
                    <FragmentList />
                    <Footer />
                </Layout.view.content>
                {/* <ButtonShortCut page=""></ButtonShortCut> */}
            </Layout.view>
        </ContentProvider>
    );
}

export default Class;