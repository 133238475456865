const mes = {
    label:'Mês',
    id:'mes',
    field:'mes',
    min: 1,
    max: 12,
    type:"input-integer",
    useGrouping:false,
    required:true,
    placeholder:"Informe o mês, ex.: MM"
}
const aliquota = {
    label:'Aliquota',
    id:'aliquota',
    field:'aliquota',
    min: 2,
    max: 222,        
    required:true,
    type:"input-text",
    placeholder:"Aliquota padrão é 100.00"
}
const ano = {
    label:'Ano',
    id:'ano',
    field:'ano',
    min: 2024,
    max: 9999,
    useGrouping:false,
    type:'input-integer',
    required:true,
    placeholder:"Informe o ano, ex.: 2025"
}


export { mes, ano,aliquota };
