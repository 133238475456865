import { Route, Router, Routes } from 'react-router-dom';

import Pearson from './presentation/pearson/index';
import Login from './presentation/login';
import Branch from './presentation/branch';
import Class from './presentation/class';
import Folha_pgto_item from './presentation/folha_pgto_item';
import Subscriber from './presentation/subscriber';
import Colaborador from './presentation/colaborador';
import Comissao from './presentation/comissao';
import Comissao_item from './presentation/comissao_item';
import Aliquota from './presentation/contabil_aliquota';
import Teste from './presentation/teste';
import Lotacao from './presentation/lotacao';
import Change from './presentation/change';
import Presence from './presentation/presence';

function App() {
  return (
    <div className="App">
      <Routes>        
        <Route path="/admin/register/pearson" element={<Pearson />}>
          <Route exact path=":action" element={<Pearson />} />
          <Route path=":action/:action" element={<Pearson />} />
        </Route>
        <Route path="/admin/register/branch" element={<Branch />}>
          <Route exact path=":action" element={<Branch />} />
          <Route path=":action/:action" element={<Branch />} />
        </Route>
        <Route path="/admin/register/class" element={<Class />}>
          <Route exact path=":action" element={<Class />} />
          <Route path=":action/:action" element={<Class />} />
        </Route>
        
        <Route path="/admin/register/subscriber" element={<Subscriber />}>
          <Route exact path=":action" element={<Subscriber />} />
          <Route path=":action/:action" element={<Subscriber />} />
        </Route>
        
        <Route path="/admin/register/folha_pgto_item" element={<Folha_pgto_item />}>
          <Route exact path=":action" element={<Folha_pgto_item />} />
          <Route path=":action/:action" element={<Folha_pgto_item />} />
        </Route>
        
        <Route path="/admin/register/lotacao" element={<Lotacao />}>
          <Route exact path=":action" element={<Lotacao />} />
          <Route path=":action/:action" element={<Lotacao />} />
        </Route>
        
        <Route path="/admin/register/colaborador" element={<Colaborador />}>
          <Route exact path=":action" element={<Colaborador />} />
          <Route path=":action/:action" element={<Colaborador />} />
        </Route>
        
        <Route path="/admin/register/comissao" element={<Comissao />}>
          <Route exact path=":action" element={<Comissao />} />
          <Route path=":action/:action" element={<Comissao />} />
        </Route>
        
        <Route path="/admin/register/comissao_item" element={<Comissao_item />}>
          <Route exact path=":action" element={<Comissao_item />} />
          <Route path=":action/:action" element={<Comissao_item />} />
        </Route>
        
        <Route path="/admin/register/contabil_aliquota" element={<Aliquota />}>
          <Route exact path=":action" element={<Aliquota />} />
          <Route path=":action/:action" element={<Aliquota />} />
        </Route>
        
        <Route path="/admin/register/teste" element={<Teste />}>
          <Route exact path=":action" element={<Teste />} />
          <Route path=":action/:action" element={<Teste />} />
        </Route>
        
        <Route path="/presence" element={<Presence />}>
          <Route exact path=":subscriber_id" element={<Presence />} />
        </Route>
        <Route path="/admin/change" element={<Change />}>
          <Route exact path=":client_id" element={<Change />} />
        </Route>

        <Route path="/admin/welcome" element={<Pearson />}/>
       
        <Route path="*" element={<Login />} /> 
      </Routes>
    </div>
  );
}

export default App;
