import React from 'react'

const View = ({ children }) => {

    return (
        <>
            <div id="wrapper">
                {children}
            </div>
        </>

    );
}

const Content = ({ children }) => {

    return (
        <>
            <div id="page-wrapper" className="gray-bg">
                {children}
            </div>
        </>

    );
}

View.content = Content;
export default View;


