import React, { memo } from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';

const CustomInputNumber = ({ UComponent,field, label, readonly, disabled, args, required, formik, min, max, placeholder }) => {

  const handleFieldChange = (e) => {

    let valor = e.value != null ? e.value.toString() : e.value;
    formik.setFieldValue(field, valor);
  };

  const dollar = {
    useGrouping: false,
    disabled: (args && disabled) ?? false
  }
  //let isRequired = required ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";
  let isRequired = required || formik.errors[field] ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";

  return (
    <>

      {UComponent ?
        <>
          <label htmlFor={field}>{UComponent.label}</label>
          <br />
          <InputNumber
            {...UComponent}            
            readOnly={readonly || args?.action === "view" ? true : false}
            onBlur={formik.handleBlur}
            onChange={(e) => handleFieldChange(e)}            
          />
        </> :
        <>
          <label htmlFor={field}>{label}</label>
          <br />
          <InputNumber
            id={field}
            value={formik.values[field]}
            className={`form-constrol ${isRequired} pl-1`}
            min={min ?? 0}
            max={max ?? 255}
            name={field}
            placeholder={placeholder}
            error={formik.touched[field] && formik.errors[field]}
            readOnly={readonly || args?.action === "view" ? true : false}
            onBlur={formik.handleBlur}
            onValueChange={(e) => handleFieldChange(e)}
            {...dollar}
          />
        </>
      }
      {/* {<div className='invalid-feedback'>{formik.errors[field]}</div>} */}
      {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}

    </>
  );
};

export default memo(CustomInputNumber);
