import React, { useContext, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { AuthContext } from '../Contexts/Auth/AuthContext'
//import Permission from '../utils/Permission';
import PagesMenu from '../presentation/pagesMenu.json';
import usePermission from '../utils/Permission.js';

function Aside() {

    const array = [];

    const [openMenus, setOpenMenus] = useState({}); // Controla o colapso dos menus individualmente
    const { provider_user, provider_current_client, provider_permission, provider_screen_id, provider_association } = useContext(AuthContext)
    const {
        flags,
        setFlags,
        hasStatus,
        permissionOfCS,
        hasFlag,
        setNewFlag
    } = usePermission(provider_permission);

    //console.log("aside provider_permission", provider_current_client, provider_screen_id);
    //Permission.setCurrentClientId(provider_current_client.client_id);
    //setNewFlag(provider_permission);
    console.log("provider_user.provider_permission", provider_permission, flags, permissionOfCS);



    // Alternar collapse de menus
    const toggleMenuCollapse = (menuKey) => {
        setOpenMenus({
            [menuKey]: !openMenus[menuKey], // Apenas o menu clicado será alternado
        });
    };



    useEffect(() => {
        //console.log('%c useEffect - [ASIDE]: ', 'color: #000; background-color: green;');


    }, [])
    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            <img alt="image" className="rounded-circle" src={`//cdn.singularis.com.br/img/users/128x128/${provider_user.USER_IMG}`} style={{ maxWidth: '48px' }} />
                            <Link data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="block m-t-xs font-bold">{provider_user.USER_SHORT_NAME}</span>
                                <span className="text-muted text-xs block">Configurações <b className="caret"></b></span>
                            </Link>
                            <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                <li><Link className="dropdown-item" href="profile.html">Profile</Link></li>
                                <li><Link className="dropdown-item" href="contacts.html">Contacts</Link></li>
                                <li><Link className="dropdown-item" href="mailbox.html">Mailbox</Link></li>
                                <li className="dropdown-divider"></li>
                                <li><Link className="dropdown-item" href="login.html">Logout</Link></li>
                            </ul>
                        </div>
                        <div className="logo-element">
                            IN+
                        </div>
                    </li>

                    {

                        PagesMenu.map((pages, index) => {
                            //console.group(`Lendo categoria:`, pages.category_name)
                            //console.log("Categoria: ",pages);
                            pages.pages.map((page, index2) => {
                                //console.log(`Lendo pagina:`, page.screen_name);

                                //Verifica se o usuario tem permissão para acessar determinada página
                                if (hasFlag(`A00`, page.screen_id)) {
                                    //console.log(`Tem permissão para página ${page.screen_name}`);
                                    //Se a categoria referenciada no loop não existir em {array} então cria-se 
                                    if (!array.some(screen => screen.category_name === pages.category_name)) {
                                        //console.log(`Criando ${pages.category_name}`);
                                        const newCategory = pages;
                                        //newCategory.pages = []
                                        array.push(newCategory)
                                        
                                        
                                    }

                                    //const category = array.find(screen => screen.category_name === pages.category_name);
                                    //category.pages.push()
                                    //console.log("category", category);
                                } else {
                                    //console.log(`Não tem permissão para página: ${page.screen_name}`);
                                }
                            })
                            //console.groupEnd()
                        })
                    }
                    {
                        
                    }
                    {
                        array.map((pages, index) => (
                            <li className={openMenus[index] ? 'active' : '' ?? pages.active}>
                                <Link key={index} onClick={() => toggleMenuCollapse(index)} to="#"><i className={pages.icon}></i> <span className="nav-label">{pages.category_name}</span><span className="fa arrow"></span></Link>
                                <ul className="nav nav-second-level" aria-expanded={openMenus[index] ? 'true' : 'false'}>
                                    {
                                        pages.pages.map((page, index2) => (
                                            <>
                                                {/* <li key={index2} className={`nav nav-second-level`+(pages.active?'':' collapse')} aria-expanded="false"><Link to="form_advanced.html">{page.screen_name}</Link></li> */}
                                                {
                                                    hasFlag(`A00`, page.screen_id) &&
                                                    <li key={index2} className={`nav nav-second-level collapse ${openMenus[index] ? 'in' : ''}`}><Link to={page.url}>{page.screen_name}</Link></li>
                                                }
                                            </>
                                        ))}
                                </ul>
                            </li>
                        ))
                    }
                </ul>

            </div>
        </nav>
    );
}

export default Aside;

