import React, { useEffect, useState, useContext } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import UseServicePutLotacao from './useServicePut.js';
import { ContentContext } from '../../Contexts/Contents/Context.js';
import { useLocation } from 'react-router-dom';

function UCCreateLotacao() {
    const location = useLocation();

    const { setListData, editData, action, setAction, setEditData, listData, getBroadcast } = useContext(ContentContext)

    const [onResume, setOnResume] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false);

    const initialValues = {
        "client_id": "",
        "colaborador_id": ""        
    };

    function substituirElemento(array, objetoSubstituto, indiceAlvo) {
        return array.map((elemento, indice) => {
            // Se o índice for o índice alvo, substitua o objeto
            if (elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]) {
                return objetoSubstituto[0];
            }
            // Caso contrário, mantenha o objeto original
            return elemento;
        });
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {
            let errors = {};
            const useServicePutLotacao = new UseServicePutLotacao();
            let erro = useServicePutLotacao.validation([data]);
            errors = erro;
            return errors
        },
        onSubmit: async (payload, { setSubmitting }) => {

            console.log("Agora vai " + action, payload);
            const formattingCreate = new UseServicePutLotacao();
            let msg = {};
            let formatted = formattingCreate.formatting([payload]);
            console.log("Valores formatados:", formatted);

            setIsSubmiting(true)

            try {
                let created = await formattingCreate.execute(action, formatted);
                console.log("created", created);

                if (action == "post") {
                    if (created.data.result.success.length > 0) {
                        setListData(prev => [...prev, created.data.result.success[0]]);
                        msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                    } else {
                        msg = { summary: "Falha!", detail: "Registro não foi criado.", severity: "error" };
                    }
                }

                if (action == "patch") {
                    var ok = substituirElemento(listData, created.data.result.success, "lotacao_id");
                    setListData(ok);
                    msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
                }

                setOnResume(msg);

                formik.resetForm();
                setIsSubmiting(false)
            } catch (error) {
                console.log("error", error);
                setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
                setIsSubmiting(false)
            }
            setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: true,
    })
    const handleClickCancel = (button) => {
        setAction("post")
        setEditData(null)
        formik.resetForm();
    }

    useEffect(() => {
        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [location] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        if (location.state != null) {
            const params = location.pathname.split("/");
            if (params[params.length - 1] == "patch") {
                const data = location.state.stateData;
                setAction("patch")
                setEditData(data)
                formik.setValues(data);
            }
        }
    }, [location])

    useEffect(() => {
        ;
        console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [ErrorMessage] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        console.log(ErrorMessage);
    }, [ErrorMessage])

    useEffect(() => {
        if (getBroadcast != null) {
            console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [getBroadcast] ::: fica de olho no listener ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            console.log(getBroadcast);
            setAction("post")
            formik.resetForm()
        }
    }, [getBroadcast])

    return {
        formik,
        onResume,
        isSubmiting,
        setIsSubmiting,
        editData,
        action,
        handleClickCancel
    }
}

export default UCCreateLotacao;
