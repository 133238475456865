import React, { memo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Editor } from "primereact/editor";
import CustomInputMask from './CustomInputMask';
import CustomInputMaskSEARCH from './CustomInputMaskSEARCH';
import CustomInputNumber from './CustomInputNumber';
import CustomInputText from './CustomInputText';
import CustomInputTextSEARCH from './CustomInputTextSEARCH';
import CustomInputMonetary from './CustomInputMonetary';
import CustomInputDateTime from './CustomInputDateTime';
import CustomInputDateTimeSEARCH from './CustomInputDateTimeSEARCH';
import CustomInputMonetarySEARCH from './CustomInputMonetarySEARCH';

const CustomInput = ({UComponent,field, type, label, args, readonly, formik, onBlur = null, required, className, dataSet, onChange, placeholder, max, min }) => {

    const handleFieldChange = (e) => {
        console.log("e.target", e);
        if (type == "editor") {
            formik.setFieldValue(field, e.htmlValue);
        } else {
            //const inputValue = args?.onlyNumbers ? e.target.value.replace(/\D/g, '') : e.target.value;
            formik.setFieldValue(field, e.target.value);
        }

    };

    // faz a verificação pra saber se o componente sera obrigatorio ou não
    let isRequired = "";
    if (formik != null)
        isRequired = required ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";
    return (
        <>

            {

                type == "editor" && (<><label htmlFor={field}>{label}</label><Editor value={formik.values[field]} onTextChange={(e) => handleFieldChange(e)} style={{ minHeight: '120px', resize: "vertical" }} /></>)
            }

            {/* CPF/CNPJ */}
            {
                (["input-cpf", "input-cnpj", "input-mask"].includes(type) && formik != null) && <CustomInputMask type={type} field={field} required={required} placeholder={placeholder} label={label} formik={formik} args={args} />
            }
            {
                (["input-cpf", "input-cnpj", "input-mask"].includes(type) && formik == null) && <CustomInputMaskSEARCH type={type} field={field} required={required} placeholder={placeholder} label={label} args={args} onChange={onChange} dataSet={dataSet} />
            }
            {/* FIM - CPF/CNPJ */}

            {
                (["input-date", "input-datetime"].includes(type) && formik != null) && <CustomInputDateTime type={type} field={field} required={required} placeholder={placeholder} label={label} formik={formik} args={args} />
            }

            {
                (["input-date", "input-datetime"].includes(type) && formik == null) && <CustomInputDateTimeSEARCH type={type} field={field} required={required} placeholder={placeholder} label={label} onChange={onChange} dataSet={dataSet} args={args} />
            }

            {
                (type == "input-real" && formik != null) && <CustomInputMonetary type={type} field={field} required={required} placeholder={placeholder} label={label} formik={formik} args={args} />
            }
            {
                (type == "input-real" && formik == null) && <CustomInputMonetarySEARCH type={type} field={field} required={required} placeholder={placeholder} label={label} onChange={onChange} dataSet={dataSet} args={args} />
            }

            {/* TEXT */}
            {
                (UComponent && UComponent.type == "input-text" && formik != null) && <CustomInputText UComponent={UComponent} formik={formik} args={args} />
            }
            {
                (type == "input-text" && formik != null) && <CustomInputText type={type} field={field} required={required} placeholder={placeholder} label={label} formik={formik} args={args} />
            }
            {
                (type == "text" && formik != null) && <CustomInputText type={type} field={field} required={required} readonly label={label} formik={formik} args={args} />
            }
            {
                (type == "input-text" && formik == null) && <CustomInputTextSEARCH UComponent={UComponent} type={type} field={field} required={required} onChange={onChange} dataSet={dataSet} label={label} args={args} />
            }
            {
                (type == "label" && formik == null) && <CustomInputTextSEARCH type={type} field={field} required={required} onChange={onChange} dataSet={dataSet} label={label} args={args} />
            }

            {/* FIM - TEXT */}

            {
                (type == "input-numeric" && formik != null)  && <CustomInputNumber type={type} field={field} required={required} min={min} max={max} placeholder={placeholder} label={label} formik={formik} args={args} />
            }
            {
                (type == "input-numeric" && formik == null)  && <CustomInputNumber type={type} field={field} required={required} min={min} max={max} placeholder={placeholder} label={label} formik={formik} args={args} />
            }
            {
                (UComponent && UComponent.type == "input-integer" && formik != null)  && <CustomInputNumber UComponent={UComponent} formik={formik} args={args}/>
            }
            {
                (UComponent && UComponent.type == "input-integer" && formik == null)  && <CustomInputNumber type={type} field={field} required={required} placeholder={placeholder} label={label} formik={formik} args={args} max={max} min={min}/>
            }
            {

                type == "textarea" && (<><label htmlFor={field}>{label}</label><InputTextarea type={type} id={field} name={field} value={formik.values[field]} onChange={(e) => handleFieldChange(e)} onBlur={formik.handleBlur} className={`form-control ${isRequired} pl-1`} readOnly={readonly || args?.action === "view" ? true : false} rows={5} cols={30} /></>)
            }
            {/* {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>} */}
        </>
    );
};

export default memo(CustomInput);
