import React, { useEffect, useState, useRef, useContext } from 'react'

import useRead from '../../src/domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';
import MultiSelect from './MultiSelect.V2';

const SelectMultiDbColaborador = ({ field, formik, loading, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref }) => {
    const [listnationality, isLoadingnationality, isErrornationality] = useRead({
        table: "tb_colaborador",
        src: 'colaborador',
        where: [["colaborador_id", "!=", "0"]],
        orderBy: "colaborador_name asc",
        limit: "5"
    }, ReadSearch);
    return (
        <MultiSelect
            field={field}
            label={label ?? "Nacionalidade"}
            options={[listnationality, 'tb_colaborador', 'colaborador_name', 'colaborador_id']}
            labelField="name"
            loading={isLoadingnationality}
            //listener={onListener}
            optionSelected='colaborador_name'
            required={true}
            //onChange={(e) => setSelectedCities(e.value)} 
            //onBlurHandler={()=> loadConfigCertificate()}
            placeholder="Nacionalidade"
            formik={formik}
        />

    );
}

export default SelectMultiDbColaborador;   