import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle, useCallback } from 'react'
import axios from 'axios'
import { FormatterValidator } from '../../infra/adapters/formatterValidator';
import PearsonModel from '../../presentation/pearson/model';

function extrairInformacoes(array) {
    return array.map(item => {
        return {
            class_id: item.class_id,
            class_name: item.class_name,
            course_name: item.course_name,
            status_name: item.status_name,
            whatsapp_number: item.whatsapp_number,
            manager_name: item.manager_name
        };
    });
}
export class ExportXLS extends PearsonModel {
    constructor(payload) {
        super(payload);
    }
    generate = async (data) => {
        /* const tableHeader2 = [
            { header: "ID", key: "class_id", width: 10 },
            { header: "Nº TURMA", key: "class_number", width: 30 },
            { header: "MODALIDADE", key: "modality_id", width: 30 },
            { header: "PRODUTO", key: "course_name", width: 30 },        
            { header: "VAGAS", key: "vacancy", width: 30 },        
            { header: "CIDADE SEDE", key: "seller_name", width: 30 },
            { header: "DATA INÍCIO", key: "dt_start", width: 30 },                
            { header: "LOCAL DE EVENTO", key: "location_name", width: 30 },                
            { header: "DATA FIM", key: "dt_end", width: 30 },                
            { header: "Status", key: "class_status_name", width: 30 },                
            { header: "Nome do Gestor", key: "city_name", width: 30 },
            
        ];  */
        // Verifica se o array está vazio
        if (data.body_content.length === 0) {
            return [];
        }
        console.log("data.body_contentdata.body_contentdata.body_contentdata.body_contentdata.body_contentdata.body_contentdata.body_content", data.body_content);
        const tableHeader = Object.keys(data.body_content[0]).map(propriedade => {
            let header = propriedade.toUpperCase().replace('_', ' ');
            return { header: header, key: propriedade, width: 30 };
        });
        ;
        //console.log("arquivo enviado para o XLS", FormatterValidator.masking(data.body_content, this.initialValues));
        const body = {
            "page": 2,
            "per_page": 6,
            "total": 12,
            "total_pages": 2,
            "download": true,
            "content": {
                "header": {
                    "folha_pgto_id": "2302123",
                    "now": "14/02/2024 12:12",
                    "emmiter_user_name": "Erisvaldo araujo"
                },
                "body_header": {
                    "descricao": "FOLHA02 PG CONTRATADOS JAN24",
                    "total": "R$12.029,34",
                    "competencia": "02/2024",
                    "_created_at": "14/02/2024",
                    "status": "LANÇADA"
                },
                "tableHeader": tableHeader,
                "body_content": FormatterValidator.masking(data.body_content, this.initialValues),
                "body_footer": {
                    "total": "R$12.029,34"
                },
                "footer": ""
            },
            "support": {
                "url": "https://reqres.in/#support-heading",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            },
            "configs": {
                "template": data.template,
                "type": data.type,
                "outputFilename": data.outPutFilename,
                "qrcodeOrigin": "`${el.cpf}${el.ingresso_id}`",
                "event_name": "Nome do evento",
                "date": "`06/11/2023`",
                "locale": "`Imperatriz-MA`",
                "paper": {
                    "format": "A4",
                    "orientation": "portrait",
                    "width": "210mm",
                    "height": "290mm"
                }
            }
        };
        await axios
            .post(`${process.env.REACT_APP_SERVER_PDF}/generateExcel?download=true`, body, {
                responseType: "blob", // this is important!
                headers: { Authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiZG9tYWluIjoiY29ydGV4LmFwcC5iciIsImlhdCI6MTUxNjIzOTAyMn0.Xze4MBGSSWsf1FBjK2oN-qA_9P4CsveB5JdU3quWJaE" },
            })
            .then(response => {
                //console.log("generatePdf", response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', body.configs.outputFilename + ".xls");
                document.body.appendChild(link);
                link.click();
                //activeLoader(false);
            })
            .catch(r => {
                //console.log("Houve algum erro", r);
            })

    }
}