import React, { useState, useEffect } from 'react';


import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import SingHelper from '../utils/SingHelper';

const CustomDropdown = ({ field, formik,loading, options,optionSelected, listener,required, onBlurHandler, labelField, placeholder, label,ref }) => {  

  const [selectedItem, setSelectedItem] = useState(null);
  const [getOptions, setOptions] = useState([]);
  const [getPlaceholder, setPlaceholder] = useState(placeholder);
  const [selectedCities, setSelectedCities] = useState(null);

  const getDropdownOptions = (a) => {
    ;
    //console.log("getDropdownOptions: ",a);
    return SingHelper.arrayToDropDown(
        a[0], a[1], a[2], a[3]
    );
};
  useEffect(() => {       
    ;
  console.log("DUSTOMDROPDOWN options",options);
    updateOptions();
  }, []);

  useEffect(() => {    
    if (listener) {      
      let newListener = options;
      //newListener[0]['tb_certificate_config'] = listener;      
      updateOptions(newListener);
    }
  }, [listener]);
  
  useEffect(() => {        
      let newListener = options;    
    updateOptions(newListener);
    setPlaceholder("Selecione um ítem");
  }, [loading]);

  const updateOptions = (args) => {
    const newOptions = getDropdownOptions(args ?? options);
    console.log("newOptions", newOptions);
    if (newOptions)
      setOptions(newOptions);
  };

  const handleDropdownChange = (e) => {
    console.log("Mudou valor: " ,field, e.value.code);
    if (onBlurHandler)
      onBlurHandler();
    formik.setFieldValue(field, e.value.code); // Atualiza o valor no Formik
    if(optionSelected)
    formik.setFieldValue(optionSelected, e.value.name); // Atualiza o valor no Formik
  };
  let isRequired = required || formik.errors[field] ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";

  return (
    <>
      <label htmlFor={field}>{label}</label>
      <br/>
      <MultiSelect 
        id={field}
        name={field}
        value={selectedCities} 
        onChange={(e) => setSelectedCities(e.value)}
        ref={ref}            
        filter
        //value={getOptions}
        //onChange={handleDropdownChange}
        options={getOptions}
        maxSelectedLabels={2}
        optionLabel={labelField}
        placeholder={getPlaceholder}
        className={`form-control ${isRequired} pl-1`}
        
      />
      {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}
    </>
  );
};

export default CustomDropdown;
