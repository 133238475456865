import React from 'react'
import { Link } from 'react-router-dom';
import Pages from '../presentation/pages.json';

const Title = ({ children }) => {
    return <h2>{children}</h2>
}

const BreadCrumb = ({ children, screen_name }) => {
    const page = Pages.pages[screen_name];

    console.log("./style/Breadcrumb", page, screen_name);

    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                {children}
                <div className="col-lg-12">
                    <Title>{page.screen_name}</Title>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/welcome">Início</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={page.directory_label}>{page.directory}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>{page.description}</strong>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
}

BreadCrumb.title = Title;
export default BreadCrumb;


