import React, { useEffect, useState, useContext } from 'react'

function Uadditional() {

  const [selectedValue, setSelectedValue] = useState(''); // Estado compartilhado
  const [where, setWhere] = useState(''); // Estado compartilhado

  const handleSelect1Change = (newValue) => {
    ;
    console.log("handleSelect1Change", newValue);
    if(newValue.code>0){      
      setWhere({ component: newValue.target, where: [["colaborador_id", ">", "630"]] });
    }
    //setSelectedValue(newValue); // Atualiza o estado com o valor selecionado em Select1
  };

  return {
    selectedValue,
    where,
    setSelectedValue,
    handleSelect1Change
  }
}

export default Uadditional;
