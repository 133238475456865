import React, { useEffect, useState, useRef, useContext, forwardRef } from 'react'
import { Link } from 'react-router-dom'

/* PRIME REACT */
import { Paginator } from 'primereact/paginator';

/* COMPONENTES */
import SweetButton from '../../components/SweetButton';

/* UTILITÁRIOS */
import SingMask from '../../utils/SingMask';
import usePermission from '../../utils/Permission.js';

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import FragmentModelCreate from './fragmentModalCreate';
import UseCaseGeneratePdf from './useCaseGeneratePdf.js';

/* ESTILOS CSS */
//import 'primereact/resources/themes/lara-light-purple/theme.css';
import useCase from './useCase.js';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import ShowToast from '../../components/Toast.jsx';
import { usePaginator } from '../../utils/Paginator.js'
import FragmentModelImport from './fragmentModalImport.jsx';
import FragmentModelSearch from './fragmentModalSearch.jsx';

import { Div, Table, Button, InputSearch, Utils } from '../../styles/tag.jsx';
import Layout from '../../styles/layout.jsx';


const FragmentList = forwardRef((props, ref) => {
    const btnDelete = useRef();
    const modalRef = useRef();
    const modalRefImport = useRef();
    const modalRefSearch = useRef();

    const containerRef = useRef(null);

    const { getTotalRecords } = usePaginator()
    const { isLoading, setIsLoading, onResume, first, rows, template1, state, handlerDelete, reloadList, onPageChange } = useCase();

    const { setAuth, auth, provider_user, provider_session, provider_current_client, provider_permission } = useContext(AuthContext)
    const { listData, getBroadcast, workSpace } = useContext(ContentContext)
    const [isShow_paginator, setShow_paginator] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [permission, setPermission] = useState({});
    const [isDisabledButtonDeleteGroup, setIsDisabledButtonDeleteGroup] = useState(true);
    const [checkboxValues, setCheckboxValues] = useState([]);
    /*  const [flags, setFlags] = useState({
         A04: false,
         A01: false,
         A02: false,
         A03: false,
         A04: false
     }); */

    const {
        flags,
        hasStatus,
        hasFlag
    } = usePermission(provider_permission, { screen_id: "504" });

    const handleCheckboxChange = (event) => {
        const { checked, value } = event.target;
        if (checked)
            setCheckboxValues(prev => [...prev, value]);
        else
            setCheckboxValues(prev => prev.filter(item => item !== value));
    };

    const handleCheckAll = (e) => {
        setCheckboxValues([])
        const checkboxes = containerRef.current.querySelectorAll('.form-check-input');
        checkboxes.forEach(checkbox => {
            if (isCheckedAll == true)
                setCheckboxValues(prev => [...prev, checkbox.value]);
        });
    };

    useEffect(() => {
        if (btnDelete.current != undefined) {
            let RES = checkboxValues.length > 0 ? false : true
            setIsDisabledButtonDeleteGroup(RES)
        }

    }, [checkboxValues])

    useEffect(() => {
        if (getBroadcast != null)
            if (getBroadcast.command == "resetButtonDeleteGroup")
                setIsDisabledButtonDeleteGroup(true)
    }, [getBroadcast])

    useEffect(() => {
        reloadList(null);
        /*     setPermission(provider_permission.filter(el => el.screen_id == "504")[0]);
            console.log("state.stateData[0]", state.stateData[0].folha_pgto_status_id);
            setFlags({
                A00: hasFlag("A00", "504"),
                A01: hasFlag("A01", "504"),
                A02: hasFlag("A02", "504"),
                A03: hasFlag("A03", "504"),
                A04: hasFlag("A04", "504")
            }); */
    }, [])

    useEffect(() => {
        if (isLoading == false && isReady == false)
            setIsReady(true)
    }, [isLoading])

    useEffect(() => {
        console.log('%c~ * ~ FRAGMENTLIST :: getResult foi atualizado ~ * ~', 'color: white; background-color: pink; padding: 4px;font-size: 18px');
        console.log("listData", listData);
        setCheckboxValues([])
    }, [listData])

    const openModal = (e) => {
        modalRef.current?.open(true)
    };

    const openModalImport = (e) => {
        modalRefImport.current?.open(true)
    };
    const openModalSearch = (e) => {
        setIsLoading(true);
        modalRefSearch.current?.open(true)
    };

    const openModalEdit2 = (e) => {
        modalRef.current?.open(e)
    };

    return (
        <>
            {
                isLoading &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }

            <FragmentModelCreate ref={modalRef} checkboxValues={checkboxValues} />
            <FragmentModelImport ref={modalRefImport} checkboxValues={checkboxValues} />
            <FragmentModelSearch ref={modalRefSearch} checkboxValues={checkboxValues} />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Itens da folha de pagamento</h5>
                                <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                        <i className="fa fa-wrench"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-user">
                                        <li><a href="#" className="dropdown-item">Config option 1</a>
                                        </li>
                                        <li><a href="#" className="dropdown-item">Config option 2</a>
                                        </li>
                                    </ul>
                                    <a className="close-link">
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="ibox-content">
                                {isReady == false ?
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                        </button>
                                    </div> : (
                                        <div className="card-body">
                                            <div className="listjs-table" id="customerList">
                                                <div className="row g-4 mb-3">
                                                    <div className="col-sm-auto">
                                                        <div className="list-grid-nav hstack gap-1">
                                                            {/* <button type="button" className="btn btn-success add-btn" onClick={broadCast} id="create-btn" data-bs-toggle="modal" data-bs-target="#exampleModalgrid"><i className="ri-add-line align-bottom ml-1"></i> Novo</button> */}
                                                            <button className="btn btn-success" onClick={() => openModal(true)}><i className="fa fa-plus"></i> Novo</button>
                                                            <button className="btn btn-primary ml-1" onClick={() => openModalImport(true)}><i className="fa fa-cloud-download"></i> Importar</button>
                                                            <button className="btn btn-primary ml-1" onClick={() => openModalSearch(true)}><i className="fa fa-cloud-download"></i> Buscar</button>
                                                            {
                                                                flags.A04 &&
                                                                <SweetButton
                                                                    props={{
                                                                        button: {
                                                                            disabled: isDisabledButtonDeleteGroup,
                                                                            ref: btnDelete,
                                                                            className: "btn btn-soft-danger btn-danger ml-1",
                                                                            icon: "fa fa-trash",
                                                                            text: "Excluir"
                                                                        },
                                                                        swlConfig: {
                                                                            type: "dialog",
                                                                            icon: "question",
                                                                            title: "Deseja excluir os registros selecionados?",
                                                                            text: "",
                                                                            confirmButtonText: "Sim, estou ciente.",
                                                                            confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                        },
                                                                        actions: {
                                                                            IfConfirmed: () => handlerDelete(checkboxValues), // Passar como uma função
                                                                            IfDenied: ""
                                                                        }
                                                                    }} />
                                                            }

                                                            {/* <button className="btn btn-soft-danger" onClick={() => new ExportXLS().generate({ template: "Folha de Pagamento", type: "xls", body_content: getResult, outPutFilename: "Turmas - total" })}><i className="ri-printer-line"></i></button> */}

                                                            <Button.info icon="fa fa-print" text="Imprimir" className="ml-1" onClick={() => UseCaseGeneratePdf.GeneratePdf({ workSpace: workSpace, data: listData, session: provider_user })} />

                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="d-flex justify-content-sm-end">
                                                            <div className="search-box ms-2">
                                                                <input type="text" className="form-control search"
                                                                    placeholder="Procurar..." />
                                                                <i className="ri-search-line search-icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-card mt-3 mb-1">
                                                    <table className="table align-middle table-nowrap table-striped table-bordered table-hover" id="customerTable">
                                                        <thead className="table-light">
                                                            <tr>
                                                                {
                                                                    flags.A04 &&
                                                                    <th scope="col" style={{ width: '50px' }}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} type="checkbox"
                                                                                id="checkAll" value="option" />
                                                                        </div>
                                                                    </th>
                                                                }
                                                                <th className="sort" data-sort="folha_pgto_item_id">Cód. item pgto</th>
                                                                <th className="sort" data-sort="folha_pgto_id">Folha de pagamento</th>
                                                                <th className="sort" data-sort="valor">Valor</th>
                                                                <th className="sort" data-sort="colaborador_id">Colaborador</th>
                                                                <th className="sort" data-sort="folha_pgto_item_status_id">Status</th>
                                                                <th className="sort" data-sort="_created_at">Criado em</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all" ref={containerRef}>
                                                            {!listData.length ? <>
                                                                <tr>
                                                                    <td colSpan="14">
                                                                        <Layout.utils.noResult />
                                                                    </td>
                                                                </tr>
                                                            </> :
                                                                (
                                                                    listData.map(el => (

                                                                        <tr key={el.folha_pgto_item_id}>
                                                                            {
                                                                                flags.A04 &&
                                                                                <th scope="row">
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox" checked={checkboxValues.includes(el.folha_pgto_item_id)} name="chk_child" value={el.folha_pgto_item_id} onChange={handleCheckboxChange} />
                                                                                    </div>
                                                                                </th>
                                                                            }
                                                                            <td nowrap="nowrap">{el.folha_pgto_item_id}</td>
                                                                            <td nowrap="nowrap">{el.folha_pgto_name}</td>
                                                                            <td nowrap="nowrap">{SingMask.toMonetary(el.valor)}</td>
                                                                            <td nowrap="nowrap">{el.colaborador_name}</td>
                                                                            <td nowrap="nowrap">{el.folha_pgto_item_status_name}</td>
                                                                            <td nowrap="nowrap">{SingMask.toDate(el._created_at, "dmYHi")}</td>
                                                                            <td nowrap="nowrap">
                                                                                <Button.info onClick={() => {
                                                                                    setIsLoading(true);
                                                                                    const useCaseGeneratePdf = UseCaseGeneratePdf.search({ data: el, workSpace: workSpace, session: provider_user }).then(r => {
                                                                                        if (useCaseGeneratePdf) {
                                                                                            console.log("LIST useCaseGeneratePdf", useCaseGeneratePdf);
                                                                                            setIsLoading(false);

                                                                                        }
                                                                                    })

                                                                                }} icon="fa fa-file-pdf-o" text="Relatório" />
                                                                                {
                                                                                    (workSpace.folha_pgto.folha_pgto_status_id == "50301" || workSpace.folha_pgto.folha_pgto_status_id == "50320") && <>
                                                                                        {
                                                                                            
                                                                                            <Button.success className="ml-1" onClick={() => openModalEdit2(el)} icon="fa fa-edit" text="editar" />
                                                                                        }

                                                                                        {
                                                                                            flags.A04 &&
                                                                                            <SweetButton props={{
                                                                                                button: {
                                                                                                    className: "btn btn-danger ml-1",
                                                                                                    icon: "fa fa-trash",
                                                                                                    text: "Excluir"
                                                                                                },
                                                                                                swlConfig: {
                                                                                                    type: "dialog",
                                                                                                    icon: "question",
                                                                                                    title: "Deseja excluir",
                                                                                                    text: `Cód. ${el.folha_pgto_item_id} - ${el.colaborador_name}?`,
                                                                                                    confirmButtonText: "Sim, excluir mesmo assim.",
                                                                                                    confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                                                },
                                                                                                actions: {
                                                                                                    IfConfirmed: () => handlerDelete(el.folha_pgto_item_id), // Passar como uma função
                                                                                                    IfDenied: ""
                                                                                                }
                                                                                            }} />
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )))
                                                            }
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                {
                                                                    flags.A04 && <th></th>
                                                                }
                                                                <th className="sort" data-sort="folha_pgto_item_id">Cód. item pgto</th>
                                                                <th className="sort" data-sort="folha_pgto_id">Folha de pagamento</th>
                                                                <th className="sort" data-sort="valor">Valor</th>
                                                                <th className="sort" data-sort="colaborador_id">Colaborador</th>
                                                                <th className="sort" data-sort="folha_pgto_item_status_id">Status</th>
                                                                <th className="sort" data-sort="_created_at">Criado em</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentList;

