import React from 'react'

const Content = ({ children }) => {

    return ( 
        <>
            <div className="wrapper wrapper-content animated fadeInRight">
            { children }                
            </div>
        </>

    );
}
export default Content;


