import React , { forwardRef }from "react";

// Subcomponente para o título
const Head = ({ children }) => {
    return <thead className="table-light">
        {children}
    </thead>;
};

const Body = forwardRef(({ children, className }, ref) => {
    return <tbody className={className} ref={ref}>{children}</tbody>;
});

const Th = forwardRef(({ children, data_sort,className }, ref) => {
    return <th className={className} data-sort={data_sort}>{children}</th>;
});

const Tr = forwardRef(({ children, className }, ref) => {
    return <tr className={className}>{children}</tr>;
});

const Td = forwardRef(({ children, className,colSpan,nowrap }, ref) => {
    return <td className={className} colSpan={colSpan} nowrap={nowrap}>{children}</td>;
});

// Subcomponente para o conteúdo
const Foot = ({ children }) => {
    return <tfoot>{children}</tfoot>;
};

const Div = ({ children,className }) => {
    return <div className="table-responsive table-card mt-3 mb-1">{children}</div>;
};

// Componente principal `ibox`
const Table = ({ children }) => {
    return <table className="table align-middle table-nowrap table-striped table-bordered table-hover" id="customerTable">
        {children}
    </table>; 
};

// Adicionando os subcomponentes ao `ibox`
Table.head = Head;
Table.body = Body;
Table.foot = Foot;
Table.th = Th;
Table.tr = Tr;
Table.td = Td;
Table.div = Div;

export default Table;
