import React from "react";

// Componente base Button
const Button = ({ children, className, onClick }) => {
    return (
        <button className={`btn ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};


Button.info = ({ icon, text, className, onClick }) => {
    return (
        <Button className={`btn-info ${className}`} onClick={onClick}>
            {icon && <i className={icon} aria-hidden="true"></i>} {/* Ícone */}
            {text && <span className="ms-2">{text}</span>} {/* Texto */}
        </Button>
    );
};

Button.success = ({ icon, text, className, onClick }) => {
    return (
        <Button className={`btn-success ${className}`} onClick={onClick}>
            {icon && <i className={icon} aria-hidden="true"></i>} {/* Ícone */}
            {text && <span className="ms-2">{text}</span>} {/* Texto */}
        </Button>
    );
};

export default Button;
