import React, { useEffect, useState, useContext } from 'react'
import Content from './content';
import Header from './header';
import Ibox from './ibox';
import Utils from './utils';
import Card from './card';
import BreadCrumb from './breadCrumb';
import Pagination from './pagination';
import View from './view';

const Layout = {
    content: Content,
    ibox: Ibox,
    utils: Utils,
    card: Card,
    Header: Header,
    pagination: Pagination,        
    breadCrumb: BreadCrumb,        
    view: View,        
  };
export default Layout; 