import React from "react";

// Subcomponente para o título
const Title = ({ text }) => {
  return <div className="ibox-title"><h5>{text}</h5></div>;
};

const Tools = ({ children }) => {
  return <div className="ibox-tools"><a className="collapse-link">
  <i className="fa fa-chevron-up"></i>
</a>
<a className="dropdown-toggle" data-toggle="dropdown" href="#">
  <i className="fa fa-wrench"></i>
</a>
<ul className="dropdown-menu dropdown-user">
  <li><a href="#" className="dropdown-item">Config option 1</a>
  </li>
  <li><a href="#" className="dropdown-item">Config option 2</a>
  </li>
</ul>
<a className="close-link">
  <i className="fa fa-times"></i>
</a></div>;
};

// Subcomponente para o conteúdo
const Content = ({ children }) => {
  return <div className="ibox-content">{children}</div>;
};

// Componente principal `ibox`
const Ibox = ({ children }) => {
  return <div className="ibox">{children}</div>;
};

// Adicionando os subcomponentes ao `ibox`
Ibox.title = Title;
Ibox.tools = Tools;
Ibox.content = Content;

export default Ibox;
