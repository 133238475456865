import React, { useEffect, useState, useRef, useContext } from 'react'

//import FragmentForm from './fragmentForm'; 
/* import FragmentSearch from './fragmentSearch'; */
import FragmentList from './fragmentList';
import { Aside, Header, Footer } from "../../fragments/index";
import ButtonShortCut from '../../components/ButtonShortCut.jsx'
import Permission from '../../utils/Permission.js'
import Breadcrumb from './breadcrumb.jsx'

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { loadFromLocalStorage } from '../../utils/localStorageUser'
import ContentProvider from '../../Contexts/Contents/Provider.js';
import Layout from '../../styles/layout.jsx';

function Class() {
    const { setAuth, auth, provider_session, provider_current_client } = useContext(AuthContext)
    const SCREEN_ID = '504';

    useEffect(() => {
        console.log('%c useEffect - [ ]: Seta as permissões', 'color: #000; background-color: green;');
        //Permission.setScreenId(SCREEN_ID);
        //Permission.setCurrentClientId(provider_session.CURRENT_CLIENT_ID);
        //Permission.setPermission(loadFromLocalStorage('permission'));
    }, []);

    return (
        <ContentProvider>
            <Layout.view>
                <Aside />
                <Layout.view.content>
                    <Header />
                    <Layout.breadCrumb screen_name="folha_pgto_item"/>
                    {/* <FragmentForm /> */}
                    <FragmentList />
                    <Footer />
                </Layout.view.content>
                {/* <ButtonShortCut page=""></ButtonShortCut> */}
            </Layout.view>
        </ContentProvider>
                    
                    
    );
}

export default Class;