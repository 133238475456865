import React, { useEffect, useState, useContext, useRef } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import UseServicePutFolha_pgto from './useServicePut.js';
import { ContentContext } from '../../Contexts/Contents/Context.js';
import { useLocation } from 'react-router-dom';

function UCCreateClass() {
    const location = useLocation();

    const { setListData, editData, action, setAction, setEditData, listData, getBroadcast } = useContext(ContentContext)

    const [onResume, setOnResume] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false);

    const initialValues = {
        "folha_pgto_name": "",
        "centro_custo_id": "",
        "valor_total": "0.00",
        "mes_competencia": "",
        "ano_competencia": "",
        "client_id": ""
    };

    function substituirElemento(array, objetoSubstituto, indiceAlvo) {
        return array.map((elemento, indice) => {
            // Se o índice for o índice alvo, substitua o objeto
            if (elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]) {
                return objetoSubstituto[0];
            }
            // Caso contrário, mantenha o objeto original
            return elemento;
        });
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {
            //console.log("data", data);

            let errors = {};
            const useServicePutFolha_pgto = new UseServicePutFolha_pgto();
            let erro = useServicePutFolha_pgto.validation([data]);
            errors = erro;
            return errors
        },
        onSubmit: async (payload, { setSubmitting }) => {
            ;
            console.log(" ################ onSubmit ", payload);//KJHGKJHGKJH 
             
            
            const formattingCreate = new UseServicePutFolha_pgto();
            let msg = {};
            let formatted = formattingCreate.formatting([payload]);
            //console.log("Valores formatados:", formatted);
            console.log("Enviado ao banco de dados: ", formatted);
            setIsSubmiting(true) 

            try {
                let created = await formattingCreate.execute(action, formatted);
                
                console.log("Resposta banco de dados: ", created);

                if (action == "post") {
                    if (created.data.result.success.length > 0) {
                        setListData(prev => [...prev, created.data.result.success[0]]);
                        msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                    } else {
                        msg = { summary: "Falha!", detail: "Registro não foi criado.", severity: "error" }; 
                    }
                }

                if (action == "patch") {
                    var ok = substituirElemento(listData, created.data.result.success, "folha_pgto_id");
                    setListData(ok);
                    if(payload.centro_custo_id==23)
                    formattingCreate.sendToN8n(formatted);
                console.log("formatted",formatted)
                    msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
                }

                setOnResume(msg);

                formik.resetForm();
                setIsSubmiting(false)
            } catch (error) {
                //console.log("error", error);
                setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
                setIsSubmiting(false)
            }
            setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: true,
    })

    const handleClickCancel = (button) => {
        setAction("post")
        setEditData(null)
        formik.resetForm();
    }

    useEffect(() => {
        //console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [location] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        if (location.state != null) {
            const params = location.pathname.split("/");
            if (params[params.length - 1] == "patch") {
                const data = location.state.stateData;
                setAction("patch")
                setEditData(data)
                formik.setValues(data);
            }
        }
    }, [location])

    useEffect(() => {
        ;
        //console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [ErrorMessage] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        //console.log(ErrorMessage);
    }, [ErrorMessage])

    useEffect(() => {
        if (getBroadcast != null) {
            //console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [getBroadcast] ::: fica de olho no listener ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            //console.log(getBroadcast);
            setAction("post")
            formik.resetForm()
        }
    }, [getBroadcast])

    return {
        formik,
        onResume,
        isSubmiting,
        setIsSubmiting,
        editData,
        action,
        handleClickCancel
    }
}

export default UCCreateClass;/* 

import React, { useEffect, useState, useContext } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import UseServicePutClass from './useServicePut.js';
import { ContentContext } from '../../Contexts/Contents/Context.js';
import { useLocation } from 'react-router-dom';

function UCCreateClass() {
    const location = useLocation();

    const { setListData, editData, action, setAction, setEditData, listData, getBroadcast } = useContext(ContentContext)

    const [onResume, setOnResume] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false);

    const initialValues = {
        "class_id": "",
        "class_number": "",
        "course_id": "",
        "vacancy": "",
        "dt_start": "",
        "dt_end": "",
        "client_id": "",
        "price_cash": "",
        "price_split": "",
        "workload": "",
        "observation": "",
        "location_id": "",
        "class_modality_id": "",
        "special_permission_arr": "",
        "special_command_id": "",
        "_created_user_id": "",
        "_updated_user_id": "",
        "_created_at": "",
        "_updated_at": "",
        "class_status_id": "",
        "city_id": "",
        "student_certificate_config_id": "",
        "icp_certificate_config_id": ""
    };

    function substituirElemento(array, objetoSubstituto, indiceAlvo) {
        return array.map((elemento, indice) => {
            // Se o índice for o índice alvo, substitua o objeto
            if (elemento[indiceAlvo] === objetoSubstituto[0][indiceAlvo]) {
                return objetoSubstituto[0];
            }
            // Caso contrário, mantenha o objeto original
            return elemento;
        });
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {
            //console.log("kljhkjhgkjhg");

            let errors = {};
            const useServicePutClass = new UseServicePutClass();
            let erro = useServicePutClass.validation([data]);
            errors = erro;
            return errors
        },
        onSubmit: async (payload, { setSubmitting }) => {

            //console.log("Agora vai " + action, payload);
            const formattingCreate = new UseServicePutClass();
            let msg = {};
            let formatted = formattingCreate.formatting([payload]);
            //console.log("Valores formatados:", formatted);

            setIsSubmiting(true)

            try {
                let created = await formattingCreate.execute(action, formatted);
                if (action == "post") {
                    setListData(prev => [...prev, created.data.result.success[0]]);
                    msg = { summary: "Sucesso!", detail: "Registro criado com sucesso.", severity: "success" };
                }

                if (action == "patch") {
                    setListData(substituirElemento(listData, created.data.result.success, "pearson_id"));
                    msg = { summary: "Sucesso!", detail: "Registro atualizado com sucesso.", severity: "success" };
                }

                setOnResume(msg);

                formik.resetForm();
                setIsSubmiting(false)
            } catch (error) {
                //console.log("error", error);
                setOnResume({ summary: "Falha na comunicação", detail: "Houve um erro entre o servidor e a aplicação. Tente novamente em instantes.", severity: "error" });
                setIsSubmiting(false)
            }
            setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: true,
    })
    const handleClickCancel = (button) => {
        setAction("post")
        setEditData(null)
        formik.resetForm();
    }

    useEffect(() => {
        //console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [location] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        if (location.state != null) {
            const data = location.state.stateData;
            setAction("patch")
            setEditData(data)
            formik.setValues(data);
        }
    }, [location])

    useEffect(() => {

        //console.log("~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [ErrorMessage] ::: pega o patch editData ::: ~~~~~~~~~~~~~~~~~~~~~~");
        //console.log(ErrorMessage);
    }, [ErrorMessage])

    useEffect(() => {
        if (getBroadcast != null) {
            //console.log('%c ~~~~~~~~~~~~~~~~~~~~~~ ::: useEffect [getBroadcast] ::: fica de olho no listener ::: ~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            //console.log(getBroadcast);
            setAction("post")
            formik.resetForm()
        }
    }, [getBroadcast])

    return {
        formik,
        onResume,
        isSubmiting,
        setIsSubmiting,
        editData,
        action,
        handleClickCancel
    }
}

export default UCCreateClass;
 */