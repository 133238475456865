import React, { useEffect, useState, useRef, useContext, forwardRef } from 'react'
import { Link } from 'react-router-dom'

/* PRIME REACT */
import { Paginator } from 'primereact/paginator';

/* COMPONENTES */
import SweetButton from '../../components/SweetButton';

/* UTILITÁRIOS */

import usePermission from '../../utils/Permission.js';

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import FragmentModelCreate from './fragmentModalCreate';
import UseCaseGeneratePdf from './useCaseGeneratePdf.js';

import useCase from './useCase.js';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import ShowToast from '../../components/Toast.jsx';
import { usePaginator } from '../../utils/Paginator.js'
//## import FragmentModelSearch from './fragmentModalSearch.jsx';

/* LAYOUT */
import { Div, Table, InputSearch, Utils,Button } from '../../styles/tag.jsx';
import Layout from '../../styles/layout.jsx';

const FragmentList = forwardRef((props, ref) => {
    const btnDelete = useRef();
    const modalRef = useRef();
    const containerRef = useRef(null);

    const { getTotalRecords } = usePaginator()
    const { isLoading, setIsLoading, onResume, first, rows, template1, state, handlerDelete, reloadList, onPageChange } = useCase();

    const { setAuth, auth, provider_user, provider_session, provider_current_client, provider_permission } = useContext(AuthContext)
    const { listData, getBroadcast, workSpace } = useContext(ContentContext)
    const [showPagination, setShowPaginator] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [permission, setPermission] = useState({});
    const [isDisabledButtonDeleteGroup, setIsDisabledButtonDeleteGroup] = useState(true);
    const [checkboxValues, setCheckboxValues] = useState([]);

    const {
        flags, 
        setFlags,
        hasStatus,
        hasFlag
    } = usePermission(provider_permission, { screen_id: "530" });

    const handleCheckboxChange = (event) => {
        const { checked, value } = event.target;
        if (checked)
            setCheckboxValues(prev => [...prev, value]);
        else
            setCheckboxValues(prev => prev.filter(item => item !== value));
    };

    const handleCheckAll = (e) => {
        setCheckboxValues([])
        const checkboxes = containerRef.current.querySelectorAll('.form-check-input');
        checkboxes.forEach(checkbox => {
            if (isCheckedAll == true)
                setCheckboxValues(prev => [...prev, checkbox.value]);
        });
    };

    useEffect(() => {
        if (btnDelete.current != undefined) {
            let RES = checkboxValues.length > 0 ? false : true
            setIsDisabledButtonDeleteGroup(RES)
        }
    }, [checkboxValues])

    useEffect(() => {
        if (getBroadcast != null)
            if (getBroadcast.command == "resetButtonDeleteGroup")
                setIsDisabledButtonDeleteGroup(true)
    }, [getBroadcast])

    useEffect(() => {
        reloadList(null);
    }, [])

    useEffect(() => {
        if (isLoading == false && isReady == false)
            setIsReady(true)
    }, [isLoading])

    useEffect(() => {
        setCheckboxValues([])
    }, [listData])

    const openModal = (e) => {
        modalRef.current?.open(true)
    };

    return (
        <>
            {
                isLoading &&
                <CircleSpinnerOverlay loading={true} zIndex="9999" message="Carregando página..." overlayColor="rgba(0,153,255,0.5)" />
            }
            <FragmentModelCreate ref={modalRef} visible={false} />
            {/* 
            <FragmentModelImport ref={modalRefImport} checkboxValues={checkboxValues} /> */}
            {/* <FragmentModelSearch ref={modalRefSearch} checkboxValues={checkboxValues} /> */}
            <Layout.content>
                <Div.row>
                    <Div.col_lg_12>
                        <Layout.ibox>
                            <Layout.ibox.title text="Aliquota de serviços">
                                <Layout.ibox.tools></Layout.ibox.tools>
                            </Layout.ibox.title>
                            <Layout.ibox.content>
                                {isReady == false ?
                                    <Layout.utils.loadingPage /> : (
                                        <>
                                            <Div.row className="g-4 mb-3">
                                                <Div.col_sm_auto>
                                                    {
                                                        flags.A01 &&
                                                        <Button.success onClick={() => openModal(true)} icon="fa fa-plus" text="Novo"/>
                                                    }
                                                    {/* <button className="btn btn-soft-danger" onClick={() => new ExportXLS().generate({ template: "Folha de Pagamento", type: "xls", body_content: getResult, outPutFilename: "Turmas - total" })}><i className="ri-printer-line"></i></button> */}
                                                    {
                                                        flags.A04 &&
                                                        <SweetButton
                                                            props={{
                                                                button: {
                                                                    disabled: isDisabledButtonDeleteGroup,
                                                                    ref: btnDelete,
                                                                    className: "btn btn-soft-danger btn-danger ml-1",
                                                                    icon: "fa fa-trash",
                                                                    text: "Excluir"
                                                                },
                                                                swlConfig: {
                                                                    type: "dialog",
                                                                    icon: "question",
                                                                    title: "Deseja excluir os registros selecionados?",
                                                                    text: "",
                                                                    confirmButtonText: "Sim, estou ciente.",
                                                                    confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                },
                                                                actions: {
                                                                    IfConfirmed: () => handlerDelete(checkboxValues), // Passar como uma função
                                                                    IfDenied: ""
                                                                }
                                                            }} />
                                                    }
                                                    {
                                                        <Button.info icon="fa fa-print" text="Imprimir" className="ml-1" onClick={() => UseCaseGeneratePdf.GeneratePdf({ workSpace: workSpace, data: listData, session: provider_user })} />

                                                    }
                                                </Div.col_sm_auto>
                                                <Div className="col-sm">
                                                    <InputSearch />
                                                </Div>
                                            </Div.row>

                                            <Table.div>
                                                <Table>
                                                    <Table.head>
                                                        <Table.tr>
                                                            {
                                                                flags.A04 &&
                                                                <Table.th>
                                                                    <Utils.checkAllInput onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} />
                                                                </Table.th>
                                                            }
                                                            <Table.th className="sort" data_sort="aliquota_id">ID.</Table.th>
                                                            <Table.th className="sort" data_sort="aliquota_status_id">Aliquota</Table.th>
                                                            <Table.th className="sort" data_sort="aliquota_id">Mês</Table.th>
                                                            <Table.th className="sort" data_sort="valor">Ano</Table.th>
                                                            <Table.th>Ações</Table.th>
                                                        </Table.tr>
                                                    </Table.head>
                                                    <Table.body className="list form-check-all" ref={containerRef}>
                                                        {!listData.length ? <>
                                                            <Table.tr>
                                                                <Table.td colSpan="14">
                                                                    <Layout.utils.noResult />
                                                                </Table.td>
                                                            </Table.tr>
                                                        </> :
                                                            (
                                                                listData.map(el => (
                                                                    <Table.tr key={el.aliquota_id}>
                                                                        {
                                                                            flags.A04 &&
                                                                            <Table.td scope="row">
                                                                                <Utils.checkOneInput checked={checkboxValues.includes(el.aliquota_id)} name="chk_child" value={el.aliquota_id} onChange={handleCheckboxChange} />
                                                                            </Table.td>
                                                                        }
                                                                        <Table.td nowrap="nowrap">{el.aliquota_id}</Table.td>
                                                                        <Table.td nowrap="nowrap">{el.aliquota}</Table.td>
                                                                        <Table.td nowrap="nowrap">{el.mes}</Table.td>
                                                                        <Table.td nowrap="nowrap">{el.ano}</Table.td>
                                                                        <Table.td nowrap="nowrap">
                                                                            {
                                                                                flags.A03 &&
                                                                                <button className="btn btn-success" onClick={() => openModal(true)}><i className="fa fa-edit"></i> Editar</button>
                                                                            }
                                                                            {
                                                                                flags.A04 &&
                                                                                <SweetButton props={{
                                                                                    button: {
                                                                                        className: "btn btn-danger ml-1",
                                                                                        icon: "fa fa-trash",
                                                                                        text: "Excluir"
                                                                                    },
                                                                                    swlConfig: {
                                                                                        type: "dialog",
                                                                                        icon: "question",
                                                                                        title: "Deseja excluir",
                                                                                        text: `Cód. ${el.aliquota_id} - ${el.client_name}?`,
                                                                                        confirmButtonText: "Sim, excluir mesmo assim.",
                                                                                        confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                                    },
                                                                                    actions: {
                                                                                        IfConfirmed: () => handlerDelete(el.aliquota_id), // Passar como uma função
                                                                                        IfDenied: ""
                                                                                    }
                                                                                }} />
                                                                            }
                                                                        </Table.td>
                                                                    </Table.tr>
                                                                )))
                                                        }
                                                    </Table.body>
                                                    <Table.foot>
                                                        <Table.tr>
                                                            {
                                                                flags.A04 && <Table.th></Table.th>
                                                            }
                                                            <Table.th>ID.</Table.th>
                                                            <Table.th>Aliquota</Table.th>
                                                            <Table.th>Mês</Table.th>
                                                            <Table.th>Ano</Table.th>
                                                            <Table.th>Ações</Table.th>
                                                        </Table.tr>
                                                    </Table.foot>
                                                </Table>
                                            </Table.div>
                                            <Layout.pagination showPagination={showPagination}>
                                                <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                            </Layout.pagination>
                                        </>
                                    )}
                            </Layout.ibox.content>
                        </Layout.ibox>
                    </Div.col_lg_12>
                </Div.row>

            </Layout.content>
            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentList;